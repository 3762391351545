import type { ReactElement, ReactNode } from 'react';

export const ModalType = {
    NATIVE_INSTALL: 'native_install',
    COMPETITION: 'competition',
} as const;

export const ModalPlacement = {
    FULL_WIDTH: 'full_width',
    CENTRE: 'centre',
} as const;

export interface IModalContext {
    modalType: typeof ModalType[keyof typeof ModalType];
    modalPlacement: typeof ModalPlacement[keyof typeof ModalPlacement];
    ariaLabelledById?: string;
    ariaDescribedById?: string;
    appStoreUrl: string;
    playStoreUrl: string;
    qrCodeUrl: string;
    playStoreId: string;
}

interface IModalContent {
    content: ReactNode;
}

interface IUseModalContentProps {
    modalType: typeof ModalType[keyof typeof ModalType];
}

export type TUseModalContent = (props: IUseModalContentProps) => IModalContent;

interface DownloadAppModalProps {
    isOpen?: boolean;
    onOpen?: VoidFunction;
    onClose?: VoidFunction;
    appStoreUrl: string;
    playStoreUrl: string;
    qrCodeUrl: string;
    playStoreId: string;
    trackingProperties: {
        copy?: string;
    };
    modalType: typeof ModalType[keyof typeof ModalType];
    modalPlacement?: typeof ModalPlacement[keyof typeof ModalPlacement];
}

export interface DownloadAppModalExports {
    ContentType: typeof ModalType;
    Placement: typeof ModalPlacement;
}

export type TDownloadAppModal = (props: DownloadAppModalProps) => ReactElement;
