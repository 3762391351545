import connect from '@clearscore/redux.connect';
import { actions as profileActions, selectors as profileSelectors } from '@clearscore/redux.profile';
import { actions as dashboardActions, selectors as dashboardSelectors } from '@clearscore/redux.dashboard';
import { selectors as marketSelectors } from '@clearscore/redux.market';
import { actions as notificationActions, selectors as notificationSelectors } from '@clearscore/redux.notifications';

import Component from './component';

const mapState = (state) => ({
    profile: profileSelectors.getPredicate(state).fetch,
    market: marketSelectors.getActiveMarket(state),
    dashboard: dashboardSelectors.getPredicate(state).fetch,
    reportId: dashboardSelectors.getReportMeta(state).reportId,
    idvStatus: dashboardSelectors.getUserStatus(state).accountIDVStatus,
    currentMarketFetchStatus: marketSelectors.getFetchStatus(state),
    notificationsFetchState: notificationSelectors.fetchHasNewNotifications.getStatus(state),
    parentState: state,
});

const mapDispatch = {
    fetchProfile: profileActions.fetch,
    fetchDashboard: dashboardActions.fetch,
    fetchHasNewNotifications: notificationActions.fetchHasNewNotifications,
};

const Container = connect(mapState, mapDispatch)(Component);

export default Container;
