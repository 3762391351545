// Terms and Conditions
import { actions as alertActions } from '@clearscore/redux.alerts';
import interpolateWithComponents from '@clearscore/helpers.interpolate-with-components';
import { ZA } from '@clearscore/config.i18n';

import lang from '../../lang';
import AlertLinkWrapper from '../../../components/alert-link-wrapper';

const notification = ({ email }) =>
    alertActions.showAlert({
        alertId: 'emailverify',
        alertContent: interpolateWithComponents(lang.alertVerifyEmail, [
            {
                Component: AlertLinkWrapper,
                props: { email },
            },
        ]),
    });

const predicate = ({ emailVerified, isNewUser, marketId }) => {
    if (isNewUser && marketId === ZA.toUpperCase()) {
        return false;
    }

    return !emailVerified;
};

export default [predicate, notification];
