import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import useMedia from '@clearscore/hooks.use-media';
import { mediaQuery } from '@clearscore/rainbow.tokens';
import AppBackground from '@clearscore-group/ui.app-background';
import IconNavigation from '@shared/icon-navigation';
import { swapOffersAndBuild, useCsBuildNavExperiment } from '@shared/cs-build-nav-test';

import styles from './mobile-navigation-bar.module.css';

const MobileNavigationBar = ({ hasBlurredBackground }) => {
    const isRendered = useMedia([null, mediaQuery['rnb-mq-large']], [true, false]);

    const hasNavB = useCsBuildNavExperiment();

    useLayoutEffect(() => {
        const className = 'hasMobileNavigationBar';
        const addClassName = () => document.body.classList.add(className);
        const removeClassName = () => document.body.classList.remove(className);

        if (isRendered) addClassName();
        else removeClassName();

        return removeClassName;
    }, [isRendered]);

    // CS Build navigation test
    const modifyNavigation = (routes) => {
        if (hasNavB) {
            return swapOffersAndBuild(routes);
        }
        return routes;
    };

    if (!isRendered) return null;

    return (
        <div
            className={cx(styles.mobileNav, { [styles.hasBlurredBackground]: hasBlurredBackground })}
            id="mobile-nav-bar"
            data-unmasked
            data-id="mobile-nav-bar"
        >
            {hasBlurredBackground ? <AppBackground /> : null}

            <IconNavigation
                className={styles.linkWrapper}
                linkClassName={styles.link}
                menu="primary"
                routerTrackingSource="global-navigation"
                modifyNavigation={modifyNavigation}
            />
        </div>
    );
};

MobileNavigationBar.propTypes = {
    hasBlurredBackground: PropTypes.bool,
};

MobileNavigationBar.defaultProps = {
    hasBlurredBackground: false,
};

export default MobileNavigationBar;
