import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { js as importJs, css as importCss } from '@clearscore/helpers.injectors';
import { hasComponent, loadComponent } from '@clearscore/helpers.cache';

/**
 * Object used to store the current vertical Component and associated store,
 * which is then used maintain the store when navigating within the same vertical
 */
const currentStore = {};

/**
 * Component to lazy load a react component from the CDN, requires object which must contain
 * a component and id property
 */
const LazyLoadComponent = ({ component, handleError, handleComplete }) => {
    const { name, vertical, component: componentName } = component;
    const portaName = name.split('.').join('--');
    const [loaded, setLoaded] = useState({ Component: null, js: false, css: false });
    const assetPath = vertical || componentName;
    const cssSrc = assetPath.replace('index.js', `${name}.css`);

    const loadJs = useCallback(async () => {
        try {
            await importJs(assetPath);
            const Component = loadComponent(name) || loadComponent(portaName);
            setLoaded((prevLoaded) => ({ ...prevLoaded, Component, js: true }));
        } catch (e) {
            handleError(e);
        }
    }, [name, handleError]);

    const loadCss = useCallback(async () => {
        try {
            await importCss(cssSrc);
            setLoaded((prevLoaded) => ({ ...prevLoaded, css: true }));
        } catch (e) {
            handleError(e);
        }
    }, [name, handleError]);

    useEffect(() => {
        const componentInCache = hasComponent(name) || hasComponent(portaName);

        // If not loaded in local state, but loaded in cache, set in local state from cache
        if (!loaded.Component && componentInCache) {
            const Component = loadComponent(name) || loadComponent(portaName);
            setLoaded({ Component, js: true, css: true });
        } else if (!componentInCache) {
            // Otherwise if not cached, let's load up CSS and JS
            loadJs();
            loadCss();
        }
    }, [name, vertical, componentName, loaded.Component, loadJs, loadCss]);

    // If not everything loaded, let's return `null`
    if (!Object.values(loaded).every((val) => !!val)) {
        return null;
    }

    handleComplete();

    return (
        <loaded.Component
            {...component.parentProps}
            {...component.routeProps}
            match={component.computedMatch}
            currentStore={currentStore}
            routeConfig={component}
        />
    );
};

LazyLoadComponent.propTypes = {
    component: PropTypes.shape({
        routeProps: PropTypes.object.isRequired,
        computedMatch: PropTypes.object.isRequired,
        parentProps: PropTypes.object.isRequired,
        name: PropTypes.string.isRequired,
        vertical: PropTypes.string,
        component: PropTypes.string,
    }),
    handleComplete: PropTypes.func,
    handleError: PropTypes.func,
};

LazyLoadComponent.defaultProps = {
    component: null,
    handleError: () => {},
    handleComplete: () => {},
};

export default LazyLoadComponent;
