import React, { Fragment } from 'react';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import PropTypes from 'prop-types';
import CSProvider from '@clearscore/redux.cs-provider';
import Font from '@clearscore/rainbow.font';
import initialiseTrackingCookies from '@clearscore/helpers.initialise-tracking-cookies';
import { VerticalTakeoverProvider } from '@shared/vertical-takeover';
import * as Sentry from '@sentry/react';
import { setQueryClient } from '@shared/helpers.query-client';

import { useCoreVisitTracking } from './lib/hooks/use-tracking';
import Router from './core-router';
import { detectKeyboardUser } from './lib/a11y-outline';

// NOTE: `reset` should go before any other styles, otherwise it will brake fonts
//       and you will have specificity wars.
import './styles/reset.module.css';
import './styles/index.module.css';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: 0,
        },
    },
});
setQueryClient(queryClient); // make query-client available without hooks

const CoreRouter = ({ store, storeConfig, templates, hasPageTracking }) => {
    const { onFirstTimeVisit, onMarketingCampaignVisit } = useCoreVisitTracking();

    initialiseTrackingCookies(store, null, { onFirstTimeVisit, onMarketingCampaignVisit });
    const parentState = store.getState();

    return (
        <Fragment>
            <Font />
            <Router
                templates={templates}
                parentState={parentState}
                coreAppBridgeTypes={storeConfig.appBridgeTypes}
                hasPageTracking={hasPageTracking}
            />
        </Fragment>
    );
};

const CoreApp = ({ storeConfig, templates, hasPageTracking }) => {
    // Enable keyboard focus highlight in browser
    detectKeyboardUser();

    return (
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />

            <CSProvider storeConfig={storeConfig}>
                {({ store }) => (
                    <VerticalTakeoverProvider>
                        <CoreRouter
                            store={store}
                            storeConfig={storeConfig}
                            templates={templates}
                            hasPageTracking={hasPageTracking}
                        />
                    </VerticalTakeoverProvider>
                )}
            </CSProvider>
        </QueryClientProvider>
    );
};

CoreRouter.propTypes = {
    store: PropTypes.object.isRequired,
    /** @type {Object} config use to configure redux store, including reducers, initial state etc. */
    storeConfig: PropTypes.object.isRequired,
    /** @type {Object} mapping of config template string to template component */
    templates: PropTypes.object.isRequired,
    hasPageTracking: PropTypes.bool,
};

CoreRouter.defaultProps = {
    hasPageTracking: false,
};

CoreApp.propTypes = {
    /** @type {Object} config use to configure redux store, including reducers, initial state etc. */
    storeConfig: PropTypes.object.isRequired,
    /** @type {Object} mapping of config template string to template component */
    templates: PropTypes.object.isRequired,
    hasPageTracking: PropTypes.bool,
};

CoreApp.defaultProps = {
    hasPageTracking: false,
};

// https://github.com/getsentry/sentry-javascript/tree/master/packages/react
export default Sentry.withProfiler(CoreApp);
