import useCsAuthQuery from '@clearscore-group/ui.external-cs-auth.cs-query';
import { score as scoreConfig } from '@clearscore/service-config.home-feature';
import { useHasSegmentationGroups } from '@clearscore/hooks.use-segmentation';
import useMarketConfig from '@clearscore/hooks.use-market-config';

import marketConfig from './lib/market-config';

const FOUR_HOURS = 60000 * 240;

const useBetterScore = () => {
    const hasBetterScoreSegment = useHasSegmentationGroups('au-betterscore:test', 'za-betterscore:feature');

    const { betterScoreEnabled } = useMarketConfig(marketConfig({ enabled: hasBetterScoreSegment }));

    return { betterScoreEnabled };
};

const useBetterScoreQuery = ({ enabled } = { enabled: true }) =>
    useCsAuthQuery(
        {
            method: 'get',
            ...scoreConfig,
        },
        {
            enabled,
            refetchOnMount: false,
            staleTime: FOUR_HOURS,
        },
    );

export { useBetterScore, useBetterScoreQuery };
