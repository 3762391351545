import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useWebappTracking from '@clearscore/hooks.use-webapp-tracking';

// eslint-disable-next-line prefer-destructuring
const performance = window.performance;

const useTracking = () => {
    const track = useWebappTracking();
    const currentLocation = useLocation();

    const trackPerformanceMarker = useCallback(
        ({ markerName, markerDurationInSeconds }) =>
            track({
                name: 'tech_rum_performance_marker',
                props: {
                    path: currentLocation.pathname,
                    name: markerName,
                    time_in_seconds: markerDurationInSeconds,
                },
            }),
        [track],
    );

    return { trackPerformanceMarker };
};

const CORE_LOCATION_CHANGE = 'react_router_location_change';

export const usePerformanceMarker = ({ markerName, markerTriggerWhen }) => {
    const { trackPerformanceMarker } = useTracking();

    useEffect(() => {
        if (markerTriggerWhen) {
            const END_MARKER = 'END_MARKER';
            performance.mark(END_MARKER);
            performance.measure(markerName, CORE_LOCATION_CHANGE, END_MARKER);

            const measures = performance.getEntriesByType('measure');

            const markerDurationInMs = measures[0].duration;
            const markerDurationInSeconds = ((markerDurationInMs % 60000) / 1000).toFixed(2);

            trackPerformanceMarker({ markerName, markerDurationInSeconds });
        }
    }, [markerTriggerWhen]);
};

export const useInitalizePerformanceMarker = () => {
    const currentLocation = useLocation();

    useEffect(() => {
        performance.mark(CORE_LOCATION_CHANGE);

        return function cleanPerformanceMarker() {
            performance.clearMeasures();
        };
    }, [currentLocation]);
};
