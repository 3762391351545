import React from 'react';
import PropTypes from 'prop-types';
import Loader from '@clearscore/rainbow.loader';
import Text from '@clearscore/rainbow.text';
import cx from 'classnames';
import isInternalLink from '@clearscore/helpers.is-internal-url';
import Spacer from '@clearscore/rainbow.spacer';
import useRequest from '@clearscore/hooks.use-request';
import useLanguage from '@clearscore/hooks.use-language';
import { Link as RouterLink } from 'react-router-dom';
import { selectors as notificationsSelectors, actions as notificationActions } from '@clearscore/redux.notifications';
import NonHoverableNotificationIcon from '@clearscore/rainbow.icons.notification-outline';
import { useSelector } from 'react-redux';
import Tag from '@clearscore/rainbow.tag';

import useTracking from './lib/hooks/use-tracking';
import lang from './lib/lang';
import styles from './notifications.module.css';
import NotificationDrawer from '../notification-drawer';

const NotificationFooter = ({ isExclusive, friendlyDateCreated, disclaimer }) => (
    <div className={styles.notificationFooter}>
        {isExclusive && disclaimer ? (
            <div className={styles.disclaimer}>
                <Text.Body2 dataId="notification-disclaimer">{disclaimer}</Text.Body2>
            </div>
        ) : null}
        <div className={styles.dateCreated}>
            <Text.Body2 dataId="notification-date-created">{friendlyDateCreated}</Text.Body2>
        </div>
    </div>
);

NotificationFooter.propTypes = {
    isExclusive: PropTypes.bool.isRequired,
    friendlyDateCreated: PropTypes.string.isRequired,
    disclaimer: PropTypes.string,
};

NotificationFooter.defaultProps = {
    disclaimer: null,
};

const Notification = ({
    badge,
    description,
    destinationUrl,
    disclaimer,
    friendlyDateCreated,
    hasNewNotifications,
    id,
    isExclusive,
    isSeen,
    onClick,
    title,
    notificationType,
}) => {
    const notificationsWrapper = cx(styles.notificationsWrapper, {
        [styles.newNotification]: !isSeen && hasNewNotifications,
    });
    const hasProtocol = destinationUrl?.startsWith('http') || destinationUrl?.startsWith('//');
    const isInternal = destinationUrl && isInternalLink(destinationUrl) && !hasProtocol;
    const hash = destinationUrl?.split('#')?.[1];
    const path = destinationUrl?.split('?')[0]?.split('#')[0];
    const searchParam = destinationUrl?.split('?')?.[1];
    const exclusiveTag = isExclusive && hash ? 'div' : null;
    const nonExclusiveTag = isInternal ? RouterLink : 'a';

    // those not in segment shouldn't receive this type, but just in case.
    if (notificationType === 'modal' || !destinationUrl) return null;

    const internalLinkProps = {
        to: {
            pathname: path,
            search: searchParam,
            hash,
        },
    };

    const externalLinkProps = {
        rel: 'noopener noreferrer',
        target: '_blank',
        href: destinationUrl,
    };

    const linkProps = isInternal ? internalLinkProps : externalLinkProps;
    const Container = exclusiveTag || nonExclusiveTag;
    const ExclusiveHeader = () => (
        <Spacer all={{ bottom: Spacer.spacings.SMALL }}>
            <Tag dataId="notification-exclusive">{badge}</Tag>
        </Spacer>
    );
    const Header = () => (
        <div className={styles.notificationTitle} data-qa="notification-header">
            <Text.H3>{title}</Text.H3>
        </div>
    );

    return (
        <Container data-id={`notification-${id}`} onClick={onClick} className={notificationsWrapper} {...linkProps}>
            {isExclusive ? <ExclusiveHeader /> : <Header />}
            {description && <Text.Body2 dataId="notification-description">{description}</Text.Body2>}
            <NotificationFooter
                disclaimer={disclaimer}
                friendlyDateCreated={friendlyDateCreated}
                isExclusive={isExclusive}
            />
        </Container>
    );
};

Notification.propTypes = {
    badge: PropTypes.string,
    description: PropTypes.string,
    destinationUrl: PropTypes.string,
    disclaimer: PropTypes.string,
    friendlyDateCreated: PropTypes.string.isRequired,
    hasNewNotifications: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    isExclusive: PropTypes.bool.isRequired,
    isSeen: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    notificationType: PropTypes.oneOf(['modal', 'link', undefined]),
};

Notification.defaultProps = {
    badge: null,
    description: null,
    disclaimer: null,
    destinationUrl: null,
    notificationType: null,
};

const ModalNotification = ({ description, friendlyDateCreated, hasNewNotifications, isExclusive, isSeen, title }) => {
    /* eslint-disable  no-unused-vars */
    const language = useLanguage(lang);
    const { setDrawerWidth, setExtenderContent, closeDrawer } = NotificationDrawer.useContext();
    /* eslint-enable  no-unused-vars */
    const notificationsWrapper = cx(styles.notificationsWrapper, {
        [styles.newNotification]: !isSeen && hasNewNotifications,
    });

    const Header = () => (
        <div className={styles.notificationTitle} data-qa="notification-header">
            <Text.H3>{title}</Text.H3>
        </div>
    );

    const setModalContent = () => {
        setDrawerWidth(NotificationDrawer.Width.FULL_WIDTH);

        setExtenderContent(null);
    };

    return (
        <div className={notificationsWrapper} onClick={setModalContent}>
            <Header />
            {description && <Text.Body2 dataId="notification-description">{description}</Text.Body2>}
            <NotificationFooter friendlyDateCreated={friendlyDateCreated} isExclusive={isExclusive} />
        </div>
    );
};

ModalNotification.propTypes = {
    description: PropTypes.string.isRequired,
    friendlyDateCreated: PropTypes.string.isRequired,
    hasNewNotifications: PropTypes.bool.isRequired,
    isExclusive: PropTypes.bool.isRequired,
    isSeen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
};

const EmptyNotifications = ({ language }) => (
    <div className={styles.noNotificationsWrapper}>
        <NonHoverableNotificationIcon height={67} width={67} />
        <Spacer all={{ top: Spacer.spacings.MEDIUM }}>
            <Text.H2>{language.noNotificationsHeader}</Text.H2>
        </Spacer>
        <div className={styles.noNotificationsCopy}>
            <Text.Body1>{language.noNotificationsCopy}</Text.Body1>
        </div>
    </div>
);

EmptyNotifications.propTypes = {
    language: PropTypes.shape({
        noNotificationsHeader: PropTypes.string.isRequired,
        noNotificationsCopy: PropTypes.string.isRequired,
    }).isRequired,
};

const Notifications = ({ hasNewNotifications, handleDrawerClose }) => {
    const { trackNotificationsClick } = useTracking();
    const language = useLanguage(lang);
    const notifications = useSelector(notificationsSelectors.getNotifications(language.dateFriendlyFormat));
    const { isComplete } = useRequest([
        {
            selector: notificationsSelectors.fetch.getStatus,
            action: notificationActions.fetch(),
        },
    ]);

    if (!isComplete) {
        return (
            <div className={cx(styles.component, styles.isCentreLoader)}>
                <Loader isFullPage theme={Loader.Theme.LIGHT} />
            </div>
        );
    }

    if (isComplete && notifications?.length < 1) {
        return <EmptyNotifications language={language} />;
    }

    return (
        <div className={styles.component}>
            <div className={styles.content}>
                <div className={styles.header} data-qa="notifications-page-header">
                    <Text.H2>{language.notifications}</Text.H2>
                </div>
                {notifications.map((notification, index) => (
                    <Notification
                        key={notification.id}
                        hasNewNotifications={hasNewNotifications}
                        {...notification}
                        onClick={() => {
                            trackNotificationsClick({ index, ...notification });
                            handleDrawerClose();
                        }}
                    />
                ))}
            </div>
        </div>
    );
};

Notifications.propTypes = {
    handleDrawerClose: PropTypes.func.isRequired,
    hasNewNotifications: PropTypes.bool.isRequired,
};

export default Notifications;
