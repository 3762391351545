import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import usePrevious from '@clearscore/hooks.use-previous';
import useWebappTracking from '@clearscore/hooks.use-webapp-tracking';
import { types as dashboardTypes, selectors as dashboardSelectors } from '@clearscore/redux.dashboard';
import { types as profileTypes, selectors as profileSelectors } from '@clearscore/redux.profile';

export const useCoreErrorTracking = () => {
    const track = useWebappTracking();
    const { fetch: profileFetch } = useSelector(profileSelectors.getPredicate);
    const { fetch: dashboardFetch } = useSelector(dashboardSelectors.getPredicate);
    const { creditReportInfo } = useSelector(dashboardSelectors.getRawData) || {};
    const location = useLocation();
    const prevRoute = usePrevious(location.pathname);

    const trackError = (props) =>
        track({
            name: 'fe_core_error_logged',
            props: {
                path: prevRoute,
                ...props,
            },
        });

    useEffect(() => {
        if (profileFetch.hasFailed) {
            trackError({ failed_api_name: profileTypes.PROFILE_FETCH_ERROR });
        }
    }, [profileFetch.hasFailed]);

    useEffect(() => {
        if (dashboardFetch.hasFailed) {
            trackError({ failed_api_name: dashboardTypes.DASHBOARD_FETCH_ERROR });
        }
    }, [dashboardFetch.hasFailed]);

    useEffect(() => {
        if (dashboardFetch.isComplete && !creditReportInfo) {
            trackError({ error_message: 'report_not_found' });
        }
    }, [dashboardFetch.isComplete]);
};

const MARKETING_CAMPAIGN_EVENT = 'fe_marketing_campaign_source';
const FIRST_TIME_VISIT_EVENT = 'fe_new_prospect_visited';

export const useCoreVisitTracking = () => {
    const track = useWebappTracking();

    return {
        onFirstTimeVisit: (userIdentity) =>
            track({
                name: FIRST_TIME_VISIT_EVENT,
                props: {
                    CS_VISITED_SITE_COOKIE: userIdentity,
                },
            }),
        // eslint-disable-next-line camelcase
        onMarketingCampaignVisit: ({ utm_source, utm_medium, utm_campaign, utm_term, utm_content }) =>
            track({
                name: MARKETING_CAMPAIGN_EVENT,
                props: {
                    campaign_source: utm_source,
                    campaign_medium: utm_medium,
                    campaign_name: utm_campaign,
                    campaign_term: utm_term,
                    campaign_content: utm_content,
                },
            }),
    };
};
