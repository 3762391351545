import { useLocation } from 'react-router-dom';
import useWebappTracking from '@clearscore/hooks.use-webapp-tracking';

export default () => {
    const track = useWebappTracking();
    const { pathname } = useLocation();

    return {
        trackBanner: ({ name, alertId, ctaText, href }) =>
            track({
                name,
                props: {
                    banner_id: alertId,
                    cta_text: ctaText,
                    destination: href,
                    source: pathname,
                },
            }),
    };
};
