import { flatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { types, actions } from '@clearscore/redux.notifications';

const refreshNewNotifications = (action$) =>
    action$.pipe(
        ofType(types.fetch.success),
        flatMap(() => of(actions.fetchHasNewNotifications())),
    );

export default { refreshNewNotifications };
