import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { actions as profileActions } from '@clearscore/redux.profile';
import { actions as alertActions } from '@clearscore/redux.alerts';
import Text from '@clearscore/rainbow.text';

import useTracking from './lib/hooks/use-tracking';

const AlertLinkWrapper = ({ email, ...linkProps }) => {
    const dispatch = useDispatch();
    const { trackEmailVerifyClicked } = useTracking();

    const handleOnClick = () => {
        dispatch(profileActions.resendEmailVerification({ email }));
        dispatch(alertActions.dismissAlert());

        trackEmailVerifyClicked();
    };

    return <Text.Link {...linkProps} onClick={handleOnClick} />;
};

AlertLinkWrapper.propTypes = {
    email: PropTypes.string.isRequired,
};

export default AlertLinkWrapper;
