import React from 'react';
import { useSelector } from 'react-redux';
import { func, shape, string, node, element, oneOfType } from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import querystring from '@shared/query-string';
import { selectors as routesSelectors } from '@clearscore/redux.routes';

import { ROUTE_NO_MATCH_UNAUTH } from '../../lib/constants';

const getRedirectPath = (location) => {
    const { redirect } = querystring.parse(location.search);
    return redirect || ROUTE_NO_MATCH_UNAUTH;
};

const shouldRedirectOnLogin = (location, loggedIn) => loggedIn && location.search?.indexOf('?redirect=') === 0;

const AuthRoute = ({ Component, location, path, ...rest }) => {
    const { loggedIn } = useSelector(routesSelectors.getUserRoleRoutes);
    const redirectOnLogin = shouldRedirectOnLogin(location, loggedIn);
    return (
        <Route
            {...rest}
            path={path}
            render={({ match }) =>
                loggedIn || !redirectOnLogin ? (
                    <Component match={match} {...rest} />
                ) : (
                    <Redirect {...getRedirectPath(location)} />
                )
            }
        />
    );
};

AuthRoute.propTypes = {
    location: shape({
        search: string,
        pathname: string,
    }),
    path: string.isRequired,
    Component: oneOfType([node, element, func]).isRequired,
};

AuthRoute.defaultProps = {
    location: {},
};

export default AuthRoute;
