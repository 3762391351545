import { EN_GB } from '@clearscore/config.i18n';

export default {
    [EN_GB]: {
        description: 'Our app makes it easier than ever to track your score and find the right credit products for you',
        instructions: 'Scan the QR code with your smartphone or visit the App Store (iOS) or Google Play (Android)',
        scanMe: 'Scan me!',
        closeText: 'Close',
        qrCodeText: 'QR code image for downloading the ClearScore app',
        appStoreTitle: 'App store',
        playStoreTitle: 'Google play',
        ariaClose: 'Close',
        ariaModalContent: 'Download the ClearScore app',
        competitionDescription:
            'To enter, scan the QR code or click the buttons below to download the app and log in before the end of 10 September.',
        competitionEligibility:
            'You must be over 18 and live in the UK. No purchase needed. Only one entry per person.',
        competitionDescriptionMobile:
            'To enter, click the buttons below to download the app and log in before the end of 10 September.',
        competitionTerms: 'See full terms and conditions',
        competitionHeader: 'Win £10,000',
        competitionSubhead: 'by using the ClearScore app',
    },
};
