export default (routes) =>
    routes
        .filter((route) => parseInt(route.displayName, 10) > 0)
        .reduce((stepsArray, routeObj) => {
            const stepRoute = parseInt(routeObj.displayName, 10);
            if (!stepsArray.includes(stepRoute)) {
                stepsArray.push(stepRoute);
            }
            return stepsArray;
        }, [])
        .sort();
