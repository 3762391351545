import { EN_GB } from '@clearscore/config.i18n';

export default {
    [EN_GB]: {
        myAccount: 'My Account',
        deleteAccount: 'Delete Account',
        help: 'Help',
        logout: 'Logout',
        privacyPolicy: 'Privacy Policy',
        terms: 'Terms and Conditions',
        getTheApp: 'Get the app',
    },
};
