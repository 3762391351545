import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Icon from '@clearscore/rainbow.icons.success-inline';

import styles from './progress-indicator-with-number.module.css';

const ProgressIndicatorWithNumber = ({ isActive, step, isComplete, isOverlay }) => (
    <div className={styles.component} data-qa="progress-indicator-with-number" data-active={`${isActive}`}>
        <div
            className={cx(styles.step, {
                [styles.isActive]: isActive,
                [styles.isOverlay]: isOverlay,
            })}
            data-qa="progress-indicator-with-number-step"
        >
            {step}
        </div>
        <div
            className={cx(styles.icon, {
                [styles.isComplete]: isComplete,
            })}
            data-qa="progress-indicator-with-number-icon"
        >
            <Icon />
        </div>
    </div>
);

ProgressIndicatorWithNumber.propTypes = {
    step: PropTypes.number,
    isActive: PropTypes.bool,
    isComplete: PropTypes.bool,
    isOverlay: PropTypes.bool,
};

ProgressIndicatorWithNumber.defaultProps = {
    step: 0,
    isActive: false,
    isComplete: false,
    isOverlay: false,
};

export default ProgressIndicatorWithNumber;
