import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import Loader from '@clearscore/rainbow.loader';
import AppBackground from '@clearscore-group/ui.app-background';
import ConnectedAlert from '@clearscore-group/ui.alert';
import LazyLoadComponent from '@shared/lazy-load';

import useIdentifyOnProfileFetch from '../../libs/hooks/use-identify-on-profile-fetch';
import ProgressHeader from '../../components/progress-header';
import styles from './reg-template.module.css';

const RegTemplate = ({
    profile,
    prospect,
    fetchProfile,
    route,
    getProspect,
    prospectId,
    match,
    parentState,
    parentDispatch,
    coreAppBridgeTypes,
    currentMarketFetchStatus,
}) => {
    useIdentifyOnProfileFetch();

    const { routeProps } = route;
    const isFirstStep = routeProps.registrationStep === 1;

    useEffect(() => {
        if (isFirstStep && prospectId && prospect.isInitial) {
            getProspect(prospectId);
        }
    }, [isFirstStep, prospectId, prospect.isInitial]);

    useEffect(() => {
        if (!isFirstStep && profile.isInitial) {
            fetchProfile();
        }
    }, [isFirstStep, profile.isInitial]);

    const { registrationStep, background = {} } = routeProps;
    const { market } = match.params;
    const backgroundProps = { ...background, overrideMarket: market };
    const isNotProspectComplete = registrationStep === 1 && !prospect.isComplete;
    const isNotProfileComplete = registrationStep !== 1 && !profile.isComplete;

    if (isNotProspectComplete || isNotProfileComplete)
        return (
            <div className={styles.loader} data-qa="reg-template-spinner">
                <Loader isFullPage theme={Loader.Theme.DARK} />
            </div>
        );

    return (
        <Fragment>
            <AppBackground {...backgroundProps} />
            <ProgressHeader urlMarket={market} />

            <div className={styles.alertContainer}>
                <ConnectedAlert dataQa="reg-template-alert" />
            </div>

            <div className={styles.contentWrapper}>
                <LazyLoadComponent
                    component={{
                        ...route,
                        computedMatch: match,
                        parentProps: {
                            currentMarketFetchStatus,
                            initialState: parentState,
                            dispatch: parentDispatch,
                            coreAppBridgeTypes,
                        },
                    }}
                />
            </div>
        </Fragment>
    );
};

RegTemplate.propTypes = {
    profile: PropTypes.object.isRequired,
    fetchProfile: PropTypes.func.isRequired,
    prospect: PropTypes.object.isRequired,
    getProspect: PropTypes.func.isRequired,
    prospectId: PropTypes.string.isRequired,
    currentMarketFetchStatus: PropTypes.object.isRequired,
    route: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    parentState: PropTypes.object.isRequired,
    parentDispatch: PropTypes.func.isRequired,
    coreAppBridgeTypes: PropTypes.array.isRequired,
};

export default RegTemplate;
