import { getAuth, allClients } from '@clearscore-group/ui.external-cs-auth.add-client-instance';
import { useStore } from 'react-redux';
import useCSQuery from '@clearscore/hooks.use-cs-query';

const useAuthCSQuery = ({ client, endpoint, data, headers, method, params }, options) => {
    const store = useStore();
    const authHeaders = getAuth(client);
    const clientInstance = allClients(client, store, null);

    return useCSQuery(
        {
            client: clientInstance,
            endpoint,
            data,
            headers: { ...headers, ...authHeaders },
            method,
            params,
        },
        options,
    );
};

export default useAuthCSQuery;
