import type { ReactElement } from 'react';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import LazyLoadComponent from '@shared/lazy-load';
import { selectors as routesSelectors } from '@clearscore/redux.routes';

import type { VerticalTakeoverTriggerProps } from '../vertical-takeover.types';

export interface VerticalTakeoverLazyRendererProps {
    name: string;
    onClose: VoidFunction;
    triggerProps: VerticalTakeoverTriggerProps;
}

interface TakeoverRoutes {
    [key: string]: Record<string, unknown>;
}

const VerticalTakeoverLazyRenderer = ({
    name,
    onClose,
    triggerProps,
}: VerticalTakeoverLazyRendererProps): ReactElement | null => {
    const match = useRouteMatch();
    const takeoverRoutes: TakeoverRoutes = useSelector(routesSelectors.getTakeoverRoutes);
    const component = useMemo(
        () => Object.values(takeoverRoutes ?? {}).find(({ name: verticalName }) => name === verticalName),
        [name, takeoverRoutes],
    );
    return component ? (
        <LazyLoadComponent
            component={{
                ...component,
                computedMatch: match,
                parentProps: {
                    onClose,
                    ...triggerProps,
                },
            }}
        />
    ) : null;
};

export default VerticalTakeoverLazyRenderer;
