import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBackground from '@clearscore-group/ui.app-background';
import ConnectedAlert from '@clearscore-group/ui.alert';
import getEnv from '@clearscore/helpers.envs';
import { constants } from '@clearscore/redux.market';
import LazyLoadComponent from '@shared/lazy-load';
import monitoring from '@clearscore/helpers.monitoring';
import { ACCOUNT_STATUSES } from '@clearscore/constants.profile-statuses';
import { useHasSegmentationGroups } from '@clearscore/hooks.use-segmentation';
import Loader from '@clearscore/rainbow.loader';
import useMedia from '@clearscore/hooks.use-media';
import { mediaQuery } from '@clearscore/rainbow.tokens';

import Header from '../../components/header';
import MobileNavigationBar from '../../components/mobile-navigation-bar';
import useIdentifyOnProfileFetch from '../../libs/hooks/use-identify-on-profile-fetch';
import useNewTerms from '../../libs/hooks/use-new-terms';
import useAuthListeners from '../../libs/hooks/use-auth-listeners';

const BETTERSCORE_THEME = 'sigma';

const AppTemplate = ({
    profile,
    dashboard,
    notificationsFetchState,
    market,
    currentMarketFetchStatus,
    fetchProfile,
    fetchDashboard,
    fetchHasNewNotifications,
    route,
    parentState,
    parentDispatch,
    match,
    coreAppBridgeTypes,
    reportId,
    idvStatus,
}) => {
    const { routeProps = {} } = route;
    const { background = {} } = routeProps;
    const notificationsEnabled = useHasSegmentationGroups('test-in-app-notifications-v1:test');
    const isBetterscore = useHasSegmentationGroups('au-betterscore:test', 'za-betterscore:feature');
    const overrideTheme = isBetterscore ? BETTERSCORE_THEME : null;
    const isNativeWebview = getEnv('WEBVIEW');
    const isCoachingChat = route.displayName === 'Coaching Chat';
    const shouldHideMobileHeader = routeProps.hideMobileHeader;
    const shouldHideMobileNavBar = routeProps.hideMobileNavBar;

    const isSmallViewport = useMedia([null, mediaQuery['rnb-mq-large']], [true, false]);

    useIdentifyOnProfileFetch();
    useNewTerms();

    // Hooks listening for authentication state updates (eg. run "on login" events)
    useAuthListeners();
    useEffect(() => {
        if (profile.isInitial && market !== constants.GLOBAL && currentMarketFetchStatus.isComplete) {
            fetchProfile();
        }
    }, [currentMarketFetchStatus.isComplete, fetchProfile, market, profile.isInitial]);

    useEffect(() => {
        if (dashboard.isInitial && market !== constants.GLOBAL && currentMarketFetchStatus.isComplete) {
            fetchDashboard();
        }
    }, [currentMarketFetchStatus.isComplete, fetchDashboard, market, dashboard.isInitial]);

    // I don't want to mutate the store and bodge it, so an epic
    // lives in core which listens to the success of the below
    // action, when it is successful, we refetch to see if there's new
    // notifications, this also refreshes the view
    useEffect(() => {
        if (
            notificationsFetchState.isInitial &&
            market !== constants.GLOBAL &&
            currentMarketFetchStatus.isComplete &&
            !isNativeWebview &&
            notificationsEnabled
        ) {
            fetchHasNewNotifications();
        }
    }, [
        currentMarketFetchStatus.isComplete,
        fetchHasNewNotifications,
        market,
        notificationsFetchState.isInitial,
        isNativeWebview,
        notificationsEnabled,
    ]);

    if (!dashboard.isComplete || !profile.isComplete) {
        return <Loader isFullPage theme={Loader.Theme.LIGHT} />;
    }

    const hasDefectedReport = !!(!reportId && idvStatus === ACCOUNT_STATUSES.PASS);

    if (monitoring.hasMonitoring() && hasDefectedReport && !dashboard.isInitial && !dashboard.isPending) {
        monitoring.log(`CS-24637 - Defected Report: Market: ${market}`);
    }

    return (
        <Fragment>
            <AppBackground theme={overrideTheme} {...background} hasGradient />

            {!isNativeWebview && (!shouldHideMobileHeader || !isSmallViewport) ? (
                <Header routerTrackingSource="global-navigation" hasDefectedReport={hasDefectedReport} />
            ) : null}

            <LazyLoadComponent
                component={{
                    ...route,
                    computedMatch: match,
                    parentProps: {
                        initialState: parentState,
                        dispatch: parentDispatch,
                        coreAppBridgeTypes,
                        currentMarketFetchStatus,
                    },
                }}
            />

            {!isNativeWebview && !hasDefectedReport && !isCoachingChat && !shouldHideMobileNavBar ? (
                <MobileNavigationBar hasBlurredBackground={!!background.mobileNavBlur} />
            ) : null}

            <ConnectedAlert dataQa="app-template-alert" isBelowHeader />
        </Fragment>
    );
};

AppTemplate.propTypes = {
    profile: PropTypes.object.isRequired,
    dashboard: PropTypes.object.isRequired,
    notificationsFetchState: PropTypes.object.isRequired,
    fetchProfile: PropTypes.func.isRequired,
    fetchDashboard: PropTypes.func.isRequired,
    fetchHasNewNotifications: PropTypes.func.isRequired,
    market: PropTypes.string.isRequired,
    currentMarketFetchStatus: PropTypes.shape({
        isComplete: PropTypes.bool.isRequired,
    }).isRequired,
    route: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    parentState: PropTypes.object.isRequired,
    parentDispatch: PropTypes.func.isRequired,
    coreAppBridgeTypes: PropTypes.array.isRequired,
    reportId: PropTypes.string,
    idvStatus: PropTypes.string,
};

AppTemplate.defaultProps = {
    reportId: null,
    idvStatus: null,
};

export default AppTemplate;
