import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ConnectedAlert from '@clearscore-group/ui.alert';
import LazyLoadComponent from '@shared/lazy-load';

import styles from './public-template.module.css';

const OpenTemplate = ({ route, parentState, dispatch, match, coreAppBridgeTypes }) => (
    <Fragment>
        <div className={styles.alertContainer}>
            <ConnectedAlert />
        </div>

        <div className={styles.childrenContainer}>
            <LazyLoadComponent
                component={{
                    ...route,
                    computedMatch: match,
                    parentProps: {
                        initialState: parentState,
                        dispatch,
                        coreAppBridgeTypes,
                    },
                }}
            />
        </div>
    </Fragment>
);

OpenTemplate.propTypes = {
    route: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    parentState: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    coreAppBridgeTypes: PropTypes.array.isRequired,
};

export default OpenTemplate;
