import { parse } from 'path-to-regexp';
import { generatePath } from 'react-router-dom';

export default (path = '/', params) => {
    const isInternal = path.indexOf('http://') === 0 || path.indexOf('https://') === 0 || path.indexOf('://') === 0;
    if (isInternal) return path;
    if (params) {
        try {
            return generatePath(path, params);
        } catch (e) {
            // invalid params... but that's ok, we'll return the parsed path instead;
        }
    }
    const parsed = parse(path)[0];
    return typeof parsed === 'string' ? parsed : '/';
};
