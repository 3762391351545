import { EN_GB } from '@clearscore/config.i18n';

export default {
    [EN_GB]: {
        notificationAria: 'Notifications, no new notifications',
        newNotificationAria: 'Notifications, new notifications',
        logoLinkAriaLabel: 'ClearScore home',
        accountAriaLabel: 'Account menu',
        hi: (name) => `Hi ${name}`,
        notificationCount: (count) => `${count > 9 ? 'More than 9' : count} notification${count > 1 ? 's' : ''}`,
    },
};
