import connect from '@clearscore/redux.connect';
import { actions as profileActions, selectors as profileSelectors } from '@clearscore/redux.profile';
import { actions as prospectActions, selectors as prospectSelectors } from '@clearscore/redux.prospect';
import { selectors as routerSelectors } from '@clearscore/redux.router';
import { selectors as marketSelectors } from '@clearscore/redux.market';

import RegTemplate from './reg-template';

export const mapState = (state) => ({
    profile: profileSelectors.getPredicate(state).fetch,
    prospect: prospectSelectors.getPredicate(state).fetch,
    prospectId: routerSelectors.getRegistrationProspectId(state),
    currentMarketFetchStatus: marketSelectors.getFetchStatus(state),
    parentState: state,
});

export const mapDispatch = (dispatch, { match }) => ({
    fetchProfile: () => dispatch(profileActions.fetch()),
    getProspect: (prospectId) => dispatch(prospectActions.getProspect(prospectId, match.params.market)),
});

export default connect(mapState, mapDispatch)(RegTemplate);
