import type { ReactElement } from 'react';
import React from 'react';

import styles from './store-links.module.css';

interface PlayStoreIconProps {
    id: string;
}

type PlayStoreIcon = (props: PlayStoreIconProps) => ReactElement;

/** Due to dynamic ID requirement this cannot be in a .svg file */
const PlayStoreIcon: PlayStoreIcon = ({ id }) => (
    <svg className={styles.storeButton} fill="none" viewBox="0 0 129 38">
        <path
            fill="#000"
            d="M124.14 38H5.4a4.7 4.7 0 0 1-4.75-4.75V4.75A4.7 4.7 0 0 1 5.4 0h118.75a4.7 4.7 0 0 1 4.75 4.75v28.5a4.76 4.76 0 0 1-4.75 4.75Z"
        />
        <path
            fill="#A6A6A6"
            d="M124.14.76c2.19 0 4 1.8 4 4v28.5c0 2.18-1.81 3.98-4 3.98H5.4a4.01 4.01 0 0 1-3.99-3.99V4.75c0-2.18 1.8-3.99 4-3.99h118.74Zm0-.76H5.4A4.7 4.7 0 0 0 .64 4.75v28.5A4.7 4.7 0 0 0 5.4 38h118.75a4.7 4.7 0 0 0 4.75-4.75V4.75A4.76 4.76 0 0 0 124.14 0Z"
        />
        <path
            fill="#fff"
            stroke="#fff"
            strokeMiterlimit="10"
            strokeWidth=".2"
            d="M45.67 9.7c0 .75-.19 1.42-.66 1.9a2.8 2.8 0 0 1-2.1.85 2.8 2.8 0 0 1-2.08-.86 2.8 2.8 0 0 1-.86-2.09c0-.85.29-1.52.86-2.09a2.8 2.8 0 0 1 3.23-.57c.38.2.66.38.85.67l-.47.47c-.38-.47-.86-.66-1.52-.66-.57 0-1.14.19-1.52.66-.48.38-.67.95-.67 1.62s.2 1.23.67 1.61c.47.38.95.67 1.52.67.66 0 1.14-.2 1.61-.67.29-.28.48-.66.48-1.14h-2.1v-.66h2.76v.28Zm4.37-2.38h-2.56v1.8h2.37v.67h-2.37v1.8h2.56v.76h-3.32v-5.7h3.32v.67Zm3.14 5.03h-.76V7.32H50.8v-.67h4v.67h-1.62v5.03Zm4.37 0v-5.7h.76v5.7h-.76Zm3.99 0h-.76V7.32h-1.62v-.67h3.9v.67h-1.62v5.03h.1Zm9.02-.76a2.8 2.8 0 0 1-2.09.86 2.8 2.8 0 0 1-2.09-.86 2.8 2.8 0 0 1-.85-2.09c0-.85.28-1.52.85-2.09a2.8 2.8 0 0 1 2.1-.85c.85 0 1.51.28 2.08.85.57.57.86 1.24.86 2.1a2.8 2.8 0 0 1-.86 2.08Zm-3.6-.47c.37.38.94.66 1.51.66.57 0 1.14-.19 1.52-.66.38-.38.67-.95.67-1.62s-.2-1.23-.67-1.61a2.24 2.24 0 0 0-1.52-.67c-.57 0-1.14.2-1.52.67-.38.38-.66.95-.66 1.61s.19 1.24.66 1.62Zm5.5 1.23v-5.7h.86l2.75 4.47V6.65h.76v5.7h-.76L73.13 7.7v4.65h-.67Z"
        />
        <path
            fill="#fff"
            d="M65.34 20.71a4 4 0 0 0-4.09 4.09 4.05 4.05 0 0 0 4.09 4.08 4 4 0 0 0 4.08-4.08c0-2.47-1.8-4.09-4.08-4.09Zm0 6.46c-1.24 0-2.28-1.04-2.28-2.47 0-1.42 1.04-2.47 2.28-2.47 1.23 0 2.28.95 2.28 2.47 0 1.43-1.05 2.47-2.28 2.47Zm-8.84-6.46a4 4 0 0 0-4.08 4.09 4.05 4.05 0 0 0 4.08 4.08 4 4 0 0 0 4.09-4.08c0-2.47-1.8-4.09-4.09-4.09Zm0 6.46c-1.23 0-2.28-1.04-2.28-2.47 0-1.42 1.05-2.47 2.28-2.47 1.24 0 2.28.95 2.28 2.47 0 1.43-1.04 2.47-2.28 2.47Zm-10.54-5.22v1.7h4.08a3.6 3.6 0 0 1-.95 2.2 4.2 4.2 0 0 1-3.13 1.23c-2.57 0-4.47-2-4.47-4.56a4.48 4.48 0 0 1 7.6-3.33l1.24-1.23a6.09 6.09 0 0 0-4.28-1.71 6.4 6.4 0 0 0-6.36 6.27 6.4 6.4 0 0 0 6.36 6.27c1.9 0 3.23-.57 4.37-1.8a5.72 5.72 0 0 0 1.52-4c0-.38 0-.76-.1-1.04h-5.88Zm43.13 1.33c-.38-.95-1.33-2.57-3.42-2.57s-3.8 1.62-3.8 4.09a3.98 3.98 0 0 0 3.99 4.08 3.9 3.9 0 0 0 3.32-1.8l-1.33-.95c-.47.66-1.04 1.14-2 1.14-.94 0-1.51-.38-1.99-1.24l5.42-2.28-.2-.47Zm-5.51 1.33c0-1.52 1.23-2.38 2.09-2.38.66 0 1.33.38 1.52.86l-3.61 1.52Zm-4.47 3.9h1.8V16.62h-1.8V28.5Zm-2.85-6.94a3.13 3.13 0 0 0-2.18-.95c-2 0-3.9 1.8-3.9 4.08 0 2.28 1.8 4 3.9 4 .95 0 1.7-.48 2.09-.96h.1v.57c0 1.52-.86 2.38-2.2 2.38-1.04 0-1.8-.76-1.99-1.43l-1.52.67a3.92 3.92 0 0 0 3.61 2.37c2.1 0 3.8-1.23 3.8-4.18V20.9h-1.7v.67Zm-2.09 5.6c-1.23 0-2.28-1.04-2.28-2.47 0-1.42 1.05-2.47 2.28-2.47 1.24 0 2.19 1.05 2.19 2.47 0 1.43-.95 2.47-2.19 2.47Zm23.18-10.54h-4.27V28.5h1.8v-4.46h2.47c2 0 3.9-1.43 3.9-3.7 0-2.29-1.9-3.71-3.9-3.71Zm.1 5.7h-2.57v-4.09h2.57a2.09 2.09 0 0 1 2.09 2c-.1 1.04-.86 2.09-2.1 2.09Zm10.92-1.71c-1.33 0-2.66.57-3.13 1.8l1.61.67c.38-.67.95-.86 1.62-.86.95 0 1.8.57 1.9 1.52v.1a3.77 3.77 0 0 0-1.8-.48c-1.72 0-3.43.95-3.43 2.66 0 1.62 1.43 2.66 2.95 2.66 1.23 0 1.8-.57 2.28-1.14h.1v.95h1.7v-4.56c-.19-2.09-1.8-3.32-3.8-3.32Zm-.19 6.55c-.57 0-1.42-.28-1.42-1.04 0-.95 1.04-1.24 1.9-1.24.76 0 1.14.2 1.61.38a2.21 2.21 0 0 1-2.09 1.9Zm9.98-6.27-2 5.13h-.1l-2.08-5.13h-1.9l3.13 7.22-1.8 4h1.8l4.85-11.22h-1.9Zm-15.96 7.6h1.8V16.63h-1.8V28.5Z"
        />
        <path
            fill={`url(#${id}-a)`}
            d="M10.52 7.13c-.28.28-.47.76-.47 1.33v21c0 .56.19 1.04.47 1.32l.1.1L22.4 19.1v-.2L10.52 7.14Z"
        />
        <path
            fill={`url(#${id}-b)`}
            d="m26.3 23.09-3.9-3.9v-.28l3.9-3.9.09.1 4.65 2.66c1.33.76 1.33 2 0 2.75L26.3 23.1Z"
        />
        <path fill={`url(#${id}-c)`} d="m26.39 23-4-4-11.87 11.88c.48.47 1.14.47 2 .1l13.87-7.99Z" />
        <path fill={`url(#${id}-d)`} d="M26.39 15.01 12.52 7.13c-.86-.48-1.52-.38-2 .1L22.4 19l3.99-3.99Z" />
        <path
            fill="#000"
            d="m26.3 22.9-13.78 7.79c-.76.47-1.43.38-1.9 0l-.1.1.1.09c.47.38 1.14.47 1.9 0l13.77-7.98Z"
            opacity=".2"
        />
        <path
            fill="#000"
            d="M10.52 30.69c-.28-.29-.38-.76-.38-1.33v.1c0 .56.2 1.04.48 1.32v-.1h-.1Zm20.52-10.45L26.3 22.9l.1.1 4.65-2.67c.67-.38.95-.85.95-1.33 0 .48-.38.86-.95 1.24Z"
            opacity=".12"
        />
        <path
            fill="#fff"
            d="m12.52 7.22 18.52 10.55c.57.38.95.76.95 1.23 0-.47-.28-.95-.95-1.33L12.52 7.13c-1.33-.76-2.38-.1-2.38 1.42v.1c0-1.52 1.05-2.19 2.38-1.43Z"
            opacity=".25"
        />
        <defs>
            <linearGradient id={`${id}-a`} x1="21.35" x2="5.41" y1="8.28" y2="24.22" gradientUnits="userSpaceOnUse">
                <stop stopColor="#00A0FF" />
                <stop offset=".01" stopColor="#00A1FF" />
                <stop offset=".26" stopColor="#00BEFF" />
                <stop offset=".51" stopColor="#00D2FF" />
                <stop offset=".76" stopColor="#00DFFF" />
                <stop offset="1" stopColor="#00E3FF" />
            </linearGradient>
            <linearGradient id={`${id}-b`} x1="32.78" x2="9.8" y1="19" y2="19" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FFE000" />
                <stop offset=".41" stopColor="#FFBD00" />
                <stop offset=".78" stopColor="orange" />
                <stop offset="1" stopColor="#FF9C00" />
            </linearGradient>
            <linearGradient id={`${id}-c`} x1="24.23" x2="2.61" y1="21.18" y2="42.8" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FF3A44" />
                <stop offset="1" stopColor="#C31162" />
            </linearGradient>
            <linearGradient id={`${id}-d`} x1="7.58" x2="17.23" y1=".17" y2="9.82" gradientUnits="userSpaceOnUse">
                <stop stopColor="#32A071" />
                <stop offset=".07" stopColor="#2DA771" />
                <stop offset=".48" stopColor="#15CF74" />
                <stop offset=".8" stopColor="#06E775" />
                <stop offset="1" stopColor="#00F076" />
            </linearGradient>
        </defs>
    </svg>
);

export default PlayStoreIcon;
