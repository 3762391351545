import { reducers as session } from '@clearscore/redux.session';
import { reducers as dashboard } from '@clearscore/redux.dashboard';
import { reducers as profile } from '@clearscore/redux.profile';
import { reducers as routes } from '@clearscore/redux.routes';
import { reducers as transactions } from '@clearscore/redux.transactions';
import { reducers as prospect } from '@clearscore/redux.prospect';
import { reducers as market } from '@clearscore/redux.market';
import { reducers as history } from '@clearscore/redux.history';
import { reducers as products } from '@clearscore/redux.products';
import { reducers as alerts } from '@clearscore/redux.alerts';
import { reducers as notifications } from '@clearscore/redux.notifications';

export default {
    routes: routes.domain,
    dashboard: dashboard.domain,
    history: history.domain,
    profile: profile.domain,
    session: session.domain,
    transactions: transactions.domain,
    prospect: prospect.domain,
    entities: {
        ...routes.entities,
        ...dashboard.entities,
    },
    market: market.domain,
    alerts: alerts.domain,
    products: products.domain,
    notifications: notifications.domain,
};
