import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useMedia from '@clearscore/hooks.use-media';
import useMarketConfig from '@clearscore/hooks.use-market-config';
import { selectors } from '@clearscore/redux.routes';
import SvgReport from '@clearscore/rainbow.icons.document-hoverable';
import SvgBuild from '@clearscore/rainbow.icons.card-credit-builder-hoverable';
import SvgOffers from '@clearscore/rainbow.icons.credit-card-multiple-hoverable';
import SvgCoaching from '@clearscore/rainbow.icons.target-hoverable';
import SvgTimeline from '@clearscore/rainbow.icons.timeline-legacy-hoverable';
import SvgProtectShield from '@clearscore/rainbow.icons.shield-monitor-hoverable';
import SvgDrivescore from '@clearscore/rainbow.icons.drivescore-logo-hoverable';
import SvgMore from '@clearscore/rainbow.icons.more';
import SvgImprove from '@clearscore/rainbow.icons.improve-logo-hoverable';
import { mediaQuery } from '@clearscore/rainbow.tokens';
import Text from '@clearscore/rainbow.text';
import Badge from '@clearscore-group/ui.badge';
import Drawer from '@clearscore/rainbow.drawer';

import styles from './icon-navigation.module.css';
import SvgDashboard from './components/dashboard-icon';
import RouteIcon, { navIdentifier } from './components/route-icon.component';
import parsePath from './lib/parse-path';
import marketConfig from './lib/market-config';

/**
 * Icon lookup map
 * @type {object}
 */
const iconConfig = {
    report: {
        Svg: SvgReport,
    },
    offers: {
        Svg: SvgOffers,
        loadAnimation: styles.pulsateMenu,
    },
    dashboard: {
        Svg: SvgDashboard,
    },
    coaching: {
        Svg: SvgCoaching,
    },
    timeline: {
        Svg: SvgTimeline,
    },
    protect: {
        Svg: SvgProtectShield,
    },
    build: {
        Svg: SvgBuild,
    },
    drivescore: {
        Svg: SvgDrivescore,
    },
    improve: {
        Svg: SvgImprove,
    },
};

const mobileNavLinks = 4;
const maxNavIcons = 5;

/**
 * Component to render an icon based navigation
 */

const getLink =
    ({ direction, linkClass, params, routerTrackingSource }) =>
    (route) => {
        const icon = iconConfig[route.icon];
        const dataQa = route.icon || route.name;
        const pathname = parsePath(route.path, params);
        const isHorizontal = direction === 'horizontal';
        const ItemText = isHorizontal ? Text.H4 : Text.H2;

        // External links
        if (route.path.startsWith('http')) {
            return (
                <a
                    key={`${navIdentifier}${route.id}`}
                    data-qa={`${navIdentifier}${dataQa}`}
                    href={pathname}
                    className={linkClass}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <RouteIcon dataQa={dataQa} direction={direction} icon={icon} />

                    {route.displayName}
                </a>
            );
        }

        return (
            <NavLink
                key={`nav-${route.id}`}
                data-qa={`nav-${dataQa}`}
                data-walkthrough={`nav-${dataQa}`}
                activeClassName="is-selected"
                className={linkClass}
                exact={route.path === '/'}
                to={{
                    pathname,
                    state: {
                        tracking: {
                            source: routerTrackingSource,
                        },
                    },
                }}
            >
                {route.noNotifications ? (
                    <span
                        className={cx(styles.badge, {
                            [styles.badgeHorizontal]: isHorizontal,
                        })}
                    >
                        <Badge value={route.noNotifications} dataId={`badge-${dataQa}`} />
                    </span>
                ) : null}

                <RouteIcon dataQa={dataQa} direction={direction} icon={icon} />

                <ItemText tag="span">{route.displayName}</ItemText>
            </NavLink>
        );
    };

const getLinks = ({ isVertical, hasMoreMenu, mobileLinks, mobileWithMoreLinks, allLinks }) => {
    if (isVertical) return mobileLinks;

    if (hasMoreMenu) return mobileWithMoreLinks;

    return allLinks;
};

const IconNavigation = ({
    className,
    linkClassName,
    routerTrackingSource,
    params,
    menu,
    modifyNavigation,
    direction,
}) => {
    const isHorizontal = direction === 'horizontal';
    const { newNavEnabled } = useMarketConfig(marketConfig);
    const isVertical = direction === 'vertical';
    const showAllLinks = useMedia([null, mediaQuery['rnb-mq-medium']], [isHorizontal, true]);
    const horizontalLinkClass = cx(linkClassName, styles.navLink, styles.linkHorizontal);
    const verticalLinkClass = cx(styles.navLink, styles.linkVertical);
    const gbNavStyle = cx(linkClassName, styles.navLinkGB, styles.linkHorizontal);
    const navStyleSelector = newNavEnabled ? gbNavStyle : horizontalLinkClass;

    let routes = useSelector((state) => selectors.routeSelector(state, { menu }));
    routes = modifyNavigation ? modifyNavigation(routes) : routes;

    const hasMoreMenu = routes?.length > maxNavIcons && !showAllLinks && !direction;
    const mobileRoutes = Array.isArray(routes) ? routes.slice(0, mobileNavLinks) : [];
    const mobileMoreRoutes = Array.isArray(routes) ? routes.slice(mobileNavLinks) : [];
    const allLinks = Array.isArray(routes)
        ? routes
              .map(getLink({ direction: 'horizontal', linkClass: navStyleSelector, routerTrackingSource, params }))
              .filter(Boolean)
        : [];
    const mobileLinks = mobileRoutes
        .map(getLink({ direction: 'vertical', linkClass: verticalLinkClass, routerTrackingSource, params }))
        .filter(Boolean);
    const mobileWithMoreLinks = mobileRoutes
        .map(getLink({ direction: 'horizontal', linkClass: horizontalLinkClass, routerTrackingSource, params }))
        .filter(Boolean);
    const mobileMoreLinks = mobileMoreRoutes
        .map(getLink({ direction: 'vertical', linkClass: verticalLinkClass, routerTrackingSource, params }))
        .filter(Boolean);

    const links = getLinks({ isVertical, hasMoreMenu, mobileLinks, mobileWithMoreLinks, allLinks });

    return (
        <Drawer placement={Drawer.Placement.RIGHT} theme={Drawer.Theme.DARK}>
            <div
                className={cx(className, styles.navigation, {
                    [styles.navHorizontal]: isHorizontal || hasMoreMenu || showAllLinks,
                    [styles.navVertical]: isVertical,
                })}
                data-qa="icon-navigation"
            >
                {links}
                {hasMoreMenu && !showAllLinks ? (
                    <Drawer.Toggle asChild>
                        <button
                            type="button"
                            data-qa="nav-more"
                            data-walkthrough="nav-more"
                            className={horizontalLinkClass}
                        >
                            <SvgMore />

                            <Text.H4 tag="span">More</Text.H4>
                        </button>
                    </Drawer.Toggle>
                ) : null}
                <Drawer.Content>
                    <div className={cx(styles.navigation, styles.navVertical)} data-qa="icon-navigation">
                        {mobileMoreLinks}
                    </div>
                </Drawer.Content>
            </div>
        </Drawer>
    );
};

IconNavigation.propTypes = {
    /** @type {string} direction of menu */
    direction: PropTypes.string,
    /** @type {string} optional classname for navigation menu */
    className: PropTypes.string,
    /** @type {string} optional classname for each link item */
    linkClassName: PropTypes.string,
    routerTrackingSource: PropTypes.string,
    menu: PropTypes.string,
    params: PropTypes.object, // can be any shape e.g. { market: 'gb' }
    modifyNavigation: PropTypes.func,
};

IconNavigation.defaultProps = {
    direction: '',
    routerTrackingSource: '',
    className: null,
    linkClassName: null,
    params: null,
    menu: null,
    modifyNavigation: null,
};

export default IconNavigation;
