import { parse } from 'query-string';
import { getCookie } from '@clearscore/helpers.cookies';
import deepEqual from 'fast-deep-equal';

const featurePrefix = 'feature-';

type FeatureKey = `${typeof featurePrefix}${string}`;

interface ParsedQueryStringFeatures {
    [key: FeatureKey]: boolean;
}

interface ParsedFeatures {
    [key: string]: boolean;
}

export const parseQueryParamFeatures = (querystring: string): ParsedFeatures | undefined => {
    const features: ParsedQueryStringFeatures = parse(querystring) as unknown as ParsedQueryStringFeatures;
    const featureReducer = (parsedFeatures: ParsedFeatures, [key, value]: [FeatureKey, string]): ParsedFeatures => {
        if (key.startsWith(featurePrefix)) {
            const featureKey = key.replace(featurePrefix, '');
            // eslint-disable-next-line no-param-reassign
            parsedFeatures[featureKey] = value === 'true';
        }
        return parsedFeatures;
    };
    const parsedFeatures = Object.entries(features).reduce(
        // @ts-expect-error Object.entries has key values of string seems to be a fun Typescript bug
        featureReducer,
        {} as ParsedFeatures,
    ) as unknown as ParsedFeatures;

    return Object.keys(parsedFeatures).length > 0 ? parsedFeatures : undefined;
};

export const shouldUpdateFeaturesCookieAndReload = (parsedFeatures: ParsedFeatures): boolean =>
    !deepEqual(getCookie('features'), parsedFeatures);
