import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectors as routesSelectors } from '@clearscore/redux.routes';

import useBranchTracking from './use-branch-tracking';

/**
 * Track user login at the point they become authenticated after submitting login form
 *
 * This would ideally live in `webapp.login` but due to the way core redirects work,
 * that vertical's code stops being executed when the user profile is fetched and
 * we need to track after market / uuid are fetched from the backend
 */
const useBranchLoginTracking = () => {
    const { trackLogin } = useBranchTracking();

    const { loggedIn } = useSelector(routesSelectors.getUserRoleRoutes);

    const history = useHistory();

    const hasSubmittedLoginForm = Boolean(history.location?.state?.hasSubmittedLoginForm);

    useEffect(() => {
        if (loggedIn && hasSubmittedLoginForm) {
            trackLogin();

            // Clear state to ensure we don't track any login more than once
            history.replace({
                pathname: history.location.pathname,
                search: history.location.search,
                state: { ...(history.location.state ?? {}), hasSubmittedLoginForm: undefined },
            });
        }
    }, [loggedIn]);
};

export default useBranchLoginTracking;
