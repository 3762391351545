import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectors as profileSelectors } from '@clearscore/redux.profile';
import useWebappTracking from '@clearscore/hooks.use-webapp-tracking';

export const TRACK_EMAIL_VERIFY_CLICKED = {
    name: 'fe_account_email_verify_clicked',
    props: {
        user_file_type: 'user_file_type',
    },
};

export default () => {
    const track = useWebappTracking();

    const { accountIDVStatus } = useSelector(profileSelectors.getStatus);

    const trackEmailVerifyClicked = useCallback(() => {
        const { name, props } = TRACK_EMAIL_VERIFY_CLICKED;

        return track({
            name,
            props: {
                [props.user_file_type]: accountIDVStatus,
            },
        });
    }, [track]);

    return {
        trackEmailVerifyClicked,
    };
};
