import React from 'react';

import type { TUseModalContent } from '../types';
import { ModalType } from '../types';
import InstallContent from '../../components/install-content';
import CompetitionContent from '../../components/competition-content';

export const useModalContent: TUseModalContent = ({ modalType }) => {
    let content;
    switch (modalType) {
        case ModalType.COMPETITION:
            content = <CompetitionContent />;
            break;
        case ModalType.NATIVE_INSTALL: {
            content = <InstallContent />;
            break;
        }
        default: {
            content = null;
            break;
        }
    }
    return { content };
};
