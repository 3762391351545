import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Media from 'react-media';
import cx from 'classnames';
import IconNavigation from '@shared/icon-navigation';
import HamburgerNavigation from '@shared/hamburger-navigation';
import connect from '@clearscore/redux.connect';
import { selectors as profileSelectors } from '@clearscore/redux.profile';
import { selectors as notificationSelectors } from '@clearscore/redux.notifications';
import useMedia from '@clearscore/hooks.use-media';
import Container from '@clearscore/rainbow.container';
import Logo from '@clearscore/rainbow.icons.clearscore-logo-wordmark';
import ClearScoreSymbol from '@clearscore/rainbow.icons.clearscore-logo-symbol';
import NotificationIcon from '@clearscore/rainbow.icons.notification-hoverable';
import { mediaQuery } from '@clearscore/rainbow.tokens';
import useLanguage from '@clearscore/hooks.use-language';
import { useHasSegmentationGroups } from '@clearscore/hooks.use-segmentation';
import Spacer from '@clearscore/rainbow.spacer';
import Drawer from '@clearscore/rainbow.drawer';
import SvgAccount from '@clearscore/rainbow.icons.account-hoverable';
import Badge from '@clearscore-group/ui.badge';

import SideMenu from '../side-menu';
import lang from './lib/lang';
import styles from './header.module.css';
import { useTracking } from './lib/hooks/use-tracking';
import NotificationDrawer from '../notification-drawer';
import NotificationBadge from '../notification-badge';

const DRAWER_CONTENT_NOTIFICATION = 'notification';
const DRAWER_CONTENT_ACCOUNT = 'account';

const Header = ({ routerTrackingSource, hasDefectedReport, isSimpleHeader }) => {
    const language = useLanguage(lang);
    const { firstName } = useSelector(profileSelectors.getSimple);
    const notifications = useSelector(notificationSelectors.getHasNewNotifications);
    const [isBurgerMenuOpen, toggleBurgerMenu] = useState(false);
    const [drawerContent, setDrawerContent] = useState('');
    const hamburgerNotifications = 0; // todo: hook up routes selector
    const accountNotifications = 0; // todo: hook up routes selector
    const matches = useMedia([null, mediaQuery['rnb-mq-huge']], [true, false]);
    const logo = matches ? <ClearScoreSymbol width={20} height={20} /> : undefined;
    const notificationsEnabled = useHasSegmentationGroups('test-in-app-notifications-v1:test');
    const { hasNew: hasNewNotifications, count: notificationCount } = notifications;
    const { trackAccountSidebarOpened, trackAccountSidebarClosed } = useTracking();

    const centerLogo = (
        <NavLink
            to="/"
            className={styles.headerCenterLogo}
            data-qa="head-center-logo"
            aria-label={language.logoLinkAriaLabel}
        >
            <div className={styles.logoWrapper}>
                <Logo />
            </div>
        </NavLink>
    );

    return (
        <Drawer
            placement={Drawer.Placement.RIGHT}
            theme={drawerContent === DRAWER_CONTENT_NOTIFICATION ? Drawer.Theme.LIGHT : Drawer.Theme.DARK}
            onClose={() => {
                if (drawerContent === DRAWER_CONTENT_ACCOUNT) trackAccountSidebarClosed();
            }}
        >
            {/* id="webapp-core-header" is required for dynamic dashboard */}
            <header role="navigation" id="webapp-core-header" data-walkthrough="webapp-core-header">
                <Container
                    size={Container.Size.HUGE}
                    theme={isSimpleHeader ? Container.Theme.DARK : Container.Theme.TRANSPARENT}
                >
                    <div className={cx(styles.header, { [styles.simpleHeader]: isSimpleHeader })}>
                        <HamburgerNavigation
                            hasBurgerMenu={false}
                            handleToggle={() => toggleBurgerMenu(!isBurgerMenuOpen)}
                            noNotifications={isBurgerMenuOpen ? undefined : hamburgerNotifications}
                            logo={logo}
                            logoLinkAriaLabel={language.logoLinkAriaLabel}
                        />
                        <Media query={mediaQuery['rnb-mq-large']}>
                            {(mediaMatches) => {
                                if (mediaMatches && !hasDefectedReport && !isSimpleHeader) {
                                    return (
                                        <div data-unmasked>
                                            <IconNavigation
                                                className={styles.headerCenter}
                                                menu="primary"
                                                routerTrackingSource={routerTrackingSource}
                                            />
                                        </div>
                                    );
                                } else if (notificationsEnabled) {
                                    return <Spacer all={{ left: Spacer.spacings.SMALL }}>{centerLogo}</Spacer>;
                                }
                                return centerLogo;
                            }}
                        </Media>
                        <div className={styles.topRightIconWrapper}>
                            <Drawer.Toggle
                                asChild
                                onClick={() => {
                                    setDrawerContent(DRAWER_CONTENT_ACCOUNT);
                                    trackAccountSidebarOpened();
                                }}
                            >
                                <button
                                    type="button"
                                    className={cx(styles.myAccountButton)}
                                    data-qa="account-open-side-menu"
                                    aria-label={language.accountAriaLabel}
                                >
                                    {!isSimpleHeader && (
                                        <div
                                            data-qa="account-open-side-menu-first-name"
                                            className={cx(styles.accountNavigationName)}
                                        >
                                            {language.hi(firstName)}
                                        </div>
                                    )}
                                    {/* !isAccountMenuOpen && */}
                                    {accountNotifications ? (
                                        <span className={styles.badge}>
                                            <Badge value={accountNotifications} />
                                        </span>
                                    ) : null}
                                    <SvgAccount width={32} height={32} />
                                </button>
                            </Drawer.Toggle>
                            {notificationsEnabled && !isSimpleHeader && (
                                <Drawer.Toggle asChild onClick={() => setDrawerContent(DRAWER_CONTENT_NOTIFICATION)}>
                                    <button
                                        type="button"
                                        className={styles.notificationsContainer}
                                        data-id="notification-icon"
                                        aria-label={
                                            hasNewNotifications
                                                ? language.newNotificationAria
                                                : language.notificationAria
                                        }
                                    >
                                        <div
                                            className={cx(styles.notificationsWrapper, {
                                                [styles.notification]: hasNewNotifications,
                                            })}
                                            data-id="notifications-section"
                                        >
                                            {hasNewNotifications ? (
                                                <NotificationBadge
                                                    count={notificationCount}
                                                    ariaText={language.notificationCount(notificationCount)}
                                                />
                                            ) : null}
                                            <NotificationIcon height={32} width={32} />
                                        </div>
                                    </button>
                                </Drawer.Toggle>
                            )}
                        </div>
                        <Drawer.Content>
                            {drawerContent === DRAWER_CONTENT_NOTIFICATION ? (
                                <NotificationDrawer hasNewNotifications={hasNewNotifications} />
                            ) : (
                                <SideMenu accountNotifications={accountNotifications} />
                            )}
                        </Drawer.Content>
                    </div>
                </Container>
            </header>
        </Drawer>
    );
};

Header.propTypes = {
    routerTrackingSource: PropTypes.string,
    hasDefectedReport: PropTypes.bool,
    isSimpleHeader: PropTypes.bool,
};

Header.defaultProps = {
    routerTrackingSource: '',
    hasDefectedReport: false,
    isSimpleHeader: false,
};

export default connect(null, null, { useRouter: true })(Header);
