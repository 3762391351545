import React from 'react';
import ReactDOM from 'react-dom';

// eslint-disable-next-line import/no-internal-modules
import '@clearscore/rainbow.tokens/tokens.css';

// todo: remove this when DriveScore gets it's own 'core'
// eslint-disable-next-line import/no-internal-modules
import '@clearscore/rainbow.drivescore.tokens/tokens.css';

import Webapp from './webapp';

ReactDOM.render(<Webapp />, document.getElementById('root'));
