import { GLOBAL, MARKETS } from '@clearscore/config.i18n';
import getEnv from '@clearscore/helpers.envs';

export default [GLOBAL, ...MARKETS].reduce(
    (acc, market) => ({
        ...acc,
        [market]: {
            helpLink: getEnv(`${market.toUpperCase()}_HELP_URL`),
            privacyLink: getEnv(`${market.toUpperCase()}_PRIVACY_URL`),
            termsLink: getEnv(`${market.toUpperCase()}_TERMS_URL`),
        },
    }),
    {},
);
