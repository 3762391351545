import { EN_GB } from '@clearscore/config.i18n';

export default {
    [EN_GB]: {
        notifications: 'Notifications',
        dateFriendlyFormat: {
            today: 'Today',
            yesterday: 'Yesterday',
            days: 'days',
            week: 'week',
            weeks: 'weeks',
            ago: 'ago',
        },
        noNotificationsHeader: 'No notifications yet',
        noNotificationsCopy: 'We will let you know when there are new changes and information about your account.',
    },
};
