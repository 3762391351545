import { GB, ZA, AU } from '@clearscore/config.i18n';

export default ({ market = GB }) => {
    switch (market) {
        case AU:
            return {
                progressIndicatorEnabled: false,
            };
        case ZA:
        case GB:
        default:
            return {
                progressIndicatorEnabled: true,
            };
    }
};
