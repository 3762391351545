import { useEffect } from 'react';
import { useSelector, batch, useDispatch } from 'react-redux';
import formatISO9075 from 'date-fns/formatISO9075';
import { actions as profileActions, selectors as profileSelectors } from '@clearscore/redux.profile';
import { actions as alertActions, constants as alertConstants } from '@clearscore/redux.alerts';
import Text from '@clearscore/rainbow.text';
import interpolateWithComponents from '@clearscore/helpers.interpolate-with-components';
import useMarketConfig from '@clearscore/hooks.use-market-config';

import lang from '../../lang';
import marketConfig from '../../market-config';

const TNC_DOC_KEY = 'tnc';

const useTerms = () => {
    const { documentsMeta = [] } = useSelector(profileSelectors.getDocumentsMeta);
    const { version = 0 } = documentsMeta.find((doc) => doc.docType === TNC_DOC_KEY) || {};
    const { tncAgreedVersion = 0 } = useSelector(profileSelectors.getTerms);
    const haveTermsUpdated = tncAgreedVersion < version;

    return {
        version,
        haveTermsUpdated,
    };
};

const useNewTerms = () => {
    const dispatch = useDispatch();
    const { fetch } = useSelector(profileSelectors.getPredicate);
    const { termsUrl: href } = useMarketConfig(marketConfig);
    const { version, haveTermsUpdated } = useTerms();

    useEffect(() => {
        if (fetch.isComplete && haveTermsUpdated) {
            batch(() => {
                dispatch(
                    profileActions.saveTerms({
                        tncAgreedVersion: version,
                        tncAgreedDate: formatISO9075(new Date()),
                    }),
                );
                dispatch(
                    alertActions.showAlert({
                        alertContent: interpolateWithComponents(lang.termsUpdated, [
                            {
                                Component: Text.Link,
                                props: {
                                    key: 'terms-link',
                                    href,
                                    isNewTab: true,
                                    onClick: () => dispatch(alertActions.dismissAlert()),
                                },
                            },
                        ]),
                        alertType: alertConstants.ALERT_INFO,
                        isDismissible: true,
                        sticky: true,
                    }),
                );
            });
        }
    }, [fetch.isComplete]);
};

export default useNewTerms;
