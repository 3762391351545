import { GB, ZA, CA, AU } from '@clearscore/config.i18n';

export default {
    [GB]: {
        newNavEnabled: true,
    },
    [ZA]: {
        newNavEnabled: false,
    },
    [AU]: {
        newNavEnabled: false,
    },
    [CA]: {
        newNavEnabled: false,
    },
};
