import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { usePageTracking } from '@clearscore/hooks.use-tracking';
import { selectors as routesSelectors } from '@clearscore/redux.routes';
import {
    actions as marketActions,
    constants as marketConstants,
    selectors as marketSelectors,
} from '@clearscore/redux.market';
import { MARKETS } from '@clearscore/config.i18n';
import { selectors as sessionSelectors } from '@clearscore/redux.session';
import monitoring from '@clearscore/helpers.monitoring';
import { useInitalizePerformanceMarker } from '@shared/performance-marker-hook';
import { setCookie } from '@clearscore/helpers.cookies';

import { AUTHORISED, PROSPECT } from '../lib/constants';
import { useCoreErrorTracking } from '../lib/hooks/use-tracking';
import useScrollToTop from '../lib/hooks/use-scroll-to-top';
import AuthRoute from './core-routes/auth-route';
import ProspectRoute from './core-routes/prospect-route';
import NoMatchRoute from './core-routes/nomatch-route';
import UnauthRoute from './core-routes/unauth-route';
import { parseQueryParamFeatures, shouldUpdateFeaturesCookieAndReload } from '../lib/feature-query-params';

const useShouldFetchMarketConfig = (currentMarket, parentDispatch) => {
    const { fetch: currentMarketFetchStatus } = useSelector(marketSelectors.getPredicate);
    const currentRouteMarket = useSelector(routesSelectors.getCurrentRouteMarket);

    if (
        currentMarket === currentRouteMarket ||
        currentMarketFetchStatus.isPending ||
        currentMarketFetchStatus.isComplete ||
        currentMarketFetchStatus.hasFailed
    ) {
        return;
    }
    parentDispatch(marketActions.fetchMarketConfig(currentMarket));
};

const useGetRoutesToRender = (routes, location, isPending) => {
    const previousRoutes = useRef([]);
    const areRoutesLoaded = useSelector(routesSelectors.areRoutesLoaded);
    const isActiveMarketGlobal = useSelector(marketSelectors.getActiveMarket) === marketConstants.GLOBAL;
    const isMarketInRoute = location.pathname.split('/').some((path) => MARKETS.includes(path));
    const shouldRenderRoutes = isMarketInRoute || isActiveMarketGlobal || (areRoutesLoaded && !isPending);

    useEffect(() => {
        if (shouldRenderRoutes) {
            previousRoutes.current = routes;
        }
    }, [routes, shouldRenderRoutes]);

    const routesToRender = shouldRenderRoutes ? routes : previousRoutes.current;
    return routesToRender;
};

const getRouteForRoleType = (roles, currentRole) => {
    const currentRoleType = roles.find((role) => role === currentRole);
    switch (currentRoleType) {
        case AUTHORISED:
            return AuthRoute;
        case PROSPECT:
            return ProspectRoute;
        default:
            return UnauthRoute;
    }
};

const Routes = ({ parentState, templates, coreAppBridgeTypes, location, hasPageTracking }) => {
    const parentDispatch = useDispatch();
    const { routes, isPending } = useSelector(routesSelectors.getUserRoleRoutes);
    const routesToRender = useGetRoutesToRender(routes, location, isPending);
    const currentMarket = useSelector(marketSelectors.getActiveMarket);
    const currentRole = useSelector(sessionSelectors.getAuthRole);
    const parsedQueryParamFeatures = parseQueryParamFeatures(location.search);
    const history = useHistory();

    useShouldFetchMarketConfig(currentMarket, parentDispatch);
    useScrollToTop();
    usePageTracking({ hasPageTracking });
    useCoreErrorTracking();

    useInitalizePerformanceMarker();

    if (!isPending && !routesToRender.length) {
        monitoring.log('CS-37393 - White Screen No Routes');
    }

    if (parsedQueryParamFeatures && shouldUpdateFeaturesCookieAndReload(parsedQueryParamFeatures)) {
        setCookie('features', parsedQueryParamFeatures);
        history.go(0);
    }

    return (
        <Switch>
            {routesToRender.map((route) => {
                const RouteComponent = getRouteForRoleType(route.role, currentRole);
                const Template = templates[route.template];
                if (!Template) {
                    throw Error(
                        `Core-App Template Error: You requested template ${
                            route.template
                        } while we only have ${Object.keys(templates)}`,
                    );
                }
                return (
                    <RouteComponent
                        key={`route_${route.path}`}
                        path={route.path}
                        exact={route.exact}
                        location={location}
                        routes={routes}
                        Component={Template}
                        route={route}
                        coreAppBridgeTypes={coreAppBridgeTypes}
                        parentState={parentState}
                        parentDispatch={parentDispatch}
                    />
                );
            })}
            {!isPending && routesToRender.length && (
                <Route path="*">
                    <NoMatchRoute location={location} routes={routes} />
                </Route>
            )}
        </Switch>
    );
};

export default Routes;

Routes.propTypes = {
    parentState: PropTypes.object.isRequired,
    templates: PropTypes.object.isRequired,
    coreAppBridgeTypes: PropTypes.arrayOf(PropTypes.string),
    location: PropTypes.shape({
        search: PropTypes.string,
        pathname: PropTypes.string,
    }),
    hasPageTracking: PropTypes.bool,
};

Routes.defaultProps = {
    coreAppBridgeTypes: [],
    location: {},
    hasPageTracking: false,
};
