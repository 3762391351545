import { flatMap } from 'rxjs/operators';
import { of, concat, from } from 'rxjs';
import { ofType } from 'redux-observable';
import { types as profileTypes } from '@clearscore/redux.profile';
import { types as dashboardTypes } from '@clearscore/redux.dashboard';
import logout from '@clearscore-group/ui.external-cs-auth.logout';
import { actions as alertActions, constants as alertConstants } from '@clearscore/redux.alerts';

import lang from '../lang';

export default (action$, state$) =>
    action$.pipe(
        ofType(
            profileTypes.PROFILE_FETCH_ERROR,
            dashboardTypes.DASHBOARD_FETCH_ERROR,
            dashboardTypes.DASHBOARD_SERVICE_FETCH_ERROR,
        ),
        flatMap(() =>
            concat(
                from(logout(state$)),
                of(
                    alertActions.showAlert({
                        alertContent: lang.alertError,
                        alertType: alertConstants.ERROR,
                    }),
                ),
            ),
        ),
    );
