import React from 'react';
import PropTypes from 'prop-types';

import styles from './full-width-container.module.css';

const FullWidthContainer = ({ children }) => <div className={styles.container}>{children} </div>;

FullWidthContainer.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default FullWidthContainer;
