import connect from '@clearscore/redux.connect';
import { selectors as dashboardSelectors, constants as dashboardConstants } from '@clearscore/redux.dashboard';

import Component from './component';

export const mapState = (state) => {
    const { score, theme } = dashboardSelectors.getScores(state);
    const { accountIDVStatus } = dashboardSelectors.getUserStatus(state);
    const { NO_FILE } = dashboardConstants;
    const isNoFile = accountIDVStatus === NO_FILE;

    return {
        score: isNoFile ? '?' : score,
        theme: isNoFile ? 'default' : theme,
    };
};

const Container = connect(mapState)(Component);

export default Container;
