import type { ReactElement } from 'react';
import React, { Fragment, useContext } from 'react';
import QRCode from 'react-qr-code';
import cx from 'classnames';
import Text from '@clearscore/rainbow.text';
import Spacer from '@clearscore/rainbow.spacer';
import Stack from '@clearscore/rainbow.stack';
import useLanguage from '@clearscore/hooks.use-language';
import { isDesktop } from '@clearscore/helpers.device';

import styles from './install-content.module.css';
import StoreLinks from '../store-links';
import lang from '../../lib/lang';
import { ModalContext } from '../../download-app-modal';
import { ARIA_DESCRIBED_BY_ID, ARIA_LABELLED_BY_ID } from '../../lib/constants';
import { ModalPlacement } from '../../lib/types';

type InstallContent = () => ReactElement;
const InstallContent: InstallContent = () => {
    const language = useLanguage(lang);
    const isDesktopWeb = isDesktop();
    const { modalPlacement, appStoreUrl, playStoreUrl, qrCodeUrl, playStoreId } = useContext(ModalContext);

    return (
        <section
            className={cx(styles.contentBackground, {
                [styles.placementFullWidth]: modalPlacement === ModalPlacement.FULL_WIDTH,
            })}
            data-id="content-background"
        >
            <div className={styles.content}>
                <Stack all={Stack.spacings.BIG}>
                    <Text.Body1 id={ARIA_LABELLED_BY_ID}>{language.description}</Text.Body1>
                    {isDesktopWeb && qrCodeUrl ? (
                        <Fragment>
                            <div className={styles.qrCodeWrapper}>
                                <div className={styles.scanMe}>
                                    <Text type={Text.types.SMALL} weight={Text.weights.BOLD}>
                                        {language.scanMe}
                                    </Text>
                                </div>
                                <div className={styles.qrCode} aria-label={language.qrCodeText}>
                                    <QRCode size={100} value={qrCodeUrl} />
                                </div>
                            </div>
                            <Text.Body1 id={ARIA_DESCRIBED_BY_ID}>{language.instructions}</Text.Body1>
                        </Fragment>
                    ) : null}
                </Stack>
                <Spacer all={{ top: Spacer.spacings.BIG }}>
                    <StoreLinks appStoreUrl={appStoreUrl} playStoreUrl={playStoreUrl} playStoreId={playStoreId} />
                </Spacer>
            </div>
        </section>
    );
};

export default InstallContent;
