import useWebappTracking from '@clearscore/hooks.use-webapp-tracking';

export const TRACK_ACCOUNT_SIDEBAR_OPENED = 'fe_account_sidebar_opened';
export const TRACK_ACCOUNT_SIDEBAR_CLOSED = 'fe_account_sidebar_closed';

export const useTracking = () => {
    const track = useWebappTracking();

    return {
        trackAccountSidebarOpened: () =>
            track({
                name: TRACK_ACCOUNT_SIDEBAR_OPENED,
            }),
        trackAccountSidebarClosed: () =>
            track({
                name: TRACK_ACCOUNT_SIDEBAR_CLOSED,
            }),
    };
};
