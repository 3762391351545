import connect from '@clearscore/redux.connect';

import Routes from './core-router-component';

/**
 * WARNING
 * Do not add a mapDispatch as its mutates the dispatch function passed
 * to the component and prevent action bridge from working
 * */
const CoreRouter = connect(null, null, { useRouter: true })(Routes);

export default CoreRouter;
