import useWebappTracking from '@clearscore/hooks.use-webapp-tracking';

export const TRACK_ACCOUNT_CTA_CLICKED = 'fe_account_sidebar_cta_clicked';

export const useTracking = () => {
    const track = useWebappTracking();

    return {
        trackAccountCtaClicked: ({ copy }) =>
            track({
                name: TRACK_ACCOUNT_CTA_CLICKED,
                props: {
                    type: 'native_app_install',
                    copy,
                },
            }),
    };
};
