import { GB, ZA, AU, CA, GLOBAL } from '@clearscore/config.i18n';
import * as featureToggle from '@clearscore-group/ui.dev-tools.feature-toggle';

const seasonalBackgroundFeature = featureToggle.get('seasonalBackgrounds') || {};
const getTheme = ({ overrideTheme, storeTheme }: { overrideTheme?: string; storeTheme: string }): string =>
    seasonalBackgroundFeature.band || overrideTheme || storeTheme;

interface GetSeason {
    market: any;
    month: number;
}

type SeasonReturnType = 'autumn' | 'summer' | 'winter' | 'spring';

const GetSeason = ({ market, month }: GetSeason): SeasonReturnType => {
    if (seasonalBackgroundFeature.season) {
        return seasonalBackgroundFeature.season;
    }
    if ([AU, ZA].includes(market)) {
        // southern hemisphere
        switch (true) {
            case month >= 12 || month < 3:
                return 'summer';
            case month >= 3 && month < 6:
                return 'autumn';
            case month >= 6 && month < 9:
                return 'winter';
            default:
                return 'spring';
        }
    } else {
        // northern hemisphere
        switch (true) {
            case month >= 12 || month < 3:
                return 'winter';
            case month >= 3 && month < 6:
                return 'spring';
            case month >= 6 && month < 9:
                return 'summer';
            default:
                return 'autumn';
        }
    }
};

export default {
    [GB]: { seasonOverride: (): SeasonReturnType => 'autumn', getTheme: (): string => 'all' },
    [ZA]: {
        seasonOverride: ({ month }: { month: number }): SeasonReturnType => GetSeason({ market: ZA, month }),
        getTheme,
    },
    [AU]: {
        seasonOverride: ({ month }: { month: number }): SeasonReturnType => GetSeason({ market: AU, month }),
        getTheme,
    },
    [GLOBAL]: {
        seasonOverride: ({ month }: { month: number }): SeasonReturnType => GetSeason({ market: GLOBAL, month }),
        getTheme,
    },
    [CA]: {
        seasonOverride: ({ month }: { month: number }): SeasonReturnType => GetSeason({ market: CA, month }),
        getTheme: (): string => 'all',
    },
};
