import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setAlertCookie } from '@clearscore/helpers.cookies';
import { actions as alertActions, selectors as alertSelectors } from '@clearscore/redux.alerts';
import { NotificationController } from '@clearscore/rainbow.notification';

import useTracking from './lib/hooks/use-tracking';

const toUpper = (v) => (typeof v === 'string' ? v.toUpperCase() : v);

const AlertContainer = () => {
    const dispatch = useDispatch();
    const { trackBanner } = useTracking();

    const {
        isAlertOpen: isOpen,
        alertType: displayState,
        alertCloseText,
        alertContent: children,
        duration,

        ctaText = alertCloseText,
        isDismissible,
        alertId,
        href,
        title,
        isNewTab,
        alertCooldown,
    } = useSelector(alertSelectors.getAlert);

    const notificationControllerProps = {
        isOpen,
        // NOTE: there is mismatch in constants between rainbow and
        //       rest of the system rainbow use UPPERCASE.
        displayState: toUpper(displayState),
        ctaText,
        children,
        duration,
        isDismissible,
        alertId,
        href,
        title,
        isNewTab,
        dataId: displayState,
    };

    const isAlertWithCooldown = alertId && alertCooldown > 0;

    const trackIfPossible = useCallback(
        (name) => {
            if (alertId) {
                trackBanner({ name, alertId, ctaText, href });
            }
        },
        [alertId, ctaText, href, dispatch],
    );

    useEffect(() => {
        if (isOpen) {
            trackIfPossible('fe_banner_viewed');
        }
    }, [isOpen, trackIfPossible, alertId, alertCooldown]);

    const onDismiss = () => {
        dispatch(alertActions.delayedDismissAlert());
        trackIfPossible('fe_banner_dismissed');

        if (isAlertWithCooldown) {
            setAlertCookie(alertId, alertCooldown);
        }
    };

    const ctaHandler = () => {
        dispatch(alertActions.dismissAlert());
        trackIfPossible('fe_banner_clicked');

        if (isAlertWithCooldown) {
            setAlertCookie(alertId, alertCooldown);
        }
    };

    return <NotificationController isBelowHeader {...{ ...notificationControllerProps, onDismiss, ctaHandler }} />;
};

export default AlertContainer;
