import { flatMap, filter } from 'rxjs/operators';
import { of, concat, from } from 'rxjs';
import { ofType } from 'redux-observable';
import { AU } from '@clearscore/config.i18n';
import { PROFILE_STATUSES } from '@clearscore/constants.profile-statuses';
import interpolateWithComponents from '@clearscore/helpers.interpolate-with-components';
import { selectors as marketSelectors } from '@clearscore/redux.market';
import { types as dashboardTypes } from '@clearscore/redux.dashboard';
import { actions as alertActions, constants as alertConstants } from '@clearscore/redux.alerts';
import Text from '@clearscore/rainbow.text';
import logout from '@clearscore-group/ui.external-cs-auth.logout';

import marketConfig from '../market-config';
import lang from '../lang';

const showAlert = (hasAltMessage, reportNotFoundHelpLink) =>
    alertActions.showAlert({
        alertContent: interpolateWithComponents(
            hasAltMessage ? lang.alertErrorReportNotFoundAlt : lang.alertErrorReportNotFound,
            [
                {
                    Component: Text.Link,
                    props: {
                        key: 'help-link',
                        href: reportNotFoundHelpLink,
                        isNewTab: true,
                    },
                },
            ],
        ),

        alertType: alertConstants.ERROR,
    });

export default (action$, state$) =>
    action$.pipe(
        ofType(dashboardTypes.DASHBOARD_FETCH_SUCCESS),
        filter(
            ({ payload: { accountIDVStatus, creditReportInfo } }) =>
                accountIDVStatus === PROFILE_STATUSES.PASS && !creditReportInfo,
        ),
        flatMap(() => {
            const market = marketSelectors.getActiveMarket(state$.value);
            const { reportNotFoundHelpLink } = marketConfig[market];
            const hasAltMessage = [AU].includes(market);

            return concat(from(logout(state$)), of(showAlert(hasAltMessage, reportNotFoundHelpLink)));
        }),
    );

export const dashboardServiceReportNotFound = (action$, state$) =>
    action$.pipe(
        ofType(dashboardTypes.DASHBOARD_SERVICE_REPORT_NOT_FOUND_ERROR),
        flatMap(() => {
            const market = marketSelectors.getActiveMarket(state$.value);
            const { reportNotFoundHelpLink } = marketConfig[market];
            const hasAltMessage = [AU].includes(market);

            return concat(from(logout(state$)), of(showAlert(hasAltMessage, reportNotFoundHelpLink)));
        }),
    );
