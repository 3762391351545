import { flatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { push } from 'connected-react-router';
import { selectors as routeSelectors } from '@clearscore/redux.routes';
import { selectors as marketSelectors } from '@clearscore/redux.market';
import { types as profileTypes, selectors as profileSelectors } from '@clearscore/redux.profile';
import getEnv from '@clearscore/helpers.envs';
import { GB } from '@clearscore/config.i18n';
import { GB_PASS_STATUSES, ACCOUNT_STATUSES } from '@clearscore/constants.profile-statuses';

const PERMISSIONS_PATH = '/permissions';
const MY_ACCOUNT_PATH = '/my-account';
const REGISTRATION_PATH = '/registration';
const MY_ACCOUNT_MARKETING_PATH = '/my-account/marketing';
const LOGIN_PATH = '/login';
const ROOT_PATH = '/';

const allowedRoutes = [PERMISSIONS_PATH, MY_ACCOUNT_PATH, REGISTRATION_PATH];

const isOnAllowedRoute = (currentPath) =>
    allowedRoutes.some((path) => currentPath.includes(path)) && !currentPath.includes(MY_ACCOUNT_MARKETING_PATH);

/**
 * @description Middleware to handle redirects to GDPR flow
 * @param {Observable<Action>} action$ Observable
 * @param {Observable<State>} state$ Observable
 * @returns {Observable<Action>} Observable
 */
const gdprRedirectEpic = (action$, state$) => {
    const processGdprRedirect = ({ type, payload }) => {
        const isError = type === profileTypes.PROFILE_FETCH_ERROR;

        const state = {
            ...state$.value,
            profile: {
                ...state$.value.profile,
                ...payload,
            },
        };

        if (isError) {
            return of(push(LOGIN_PATH));
        }

        const currentPath = routeSelectors.getCurrentPath(state);
        const marketIsGB = marketSelectors.getActiveMarket(state) === GB;
        const { mktgPreferencesVersion } = profileSelectors.getMarketing(state);
        const { accountIDVStatus, userStatus } = profileSelectors.getStatus(state);
        const hasOutOfDateMarketingPrefVersion =
            (mktgPreferencesVersion && mktgPreferencesVersion < getEnv('MKT_PREF_VERSION')) || !mktgPreferencesVersion;
        const isPassUser = GB_PASS_STATUSES.includes(accountIDVStatus);
        const isOnGdprRoute = currentPath.includes(PERMISSIONS_PATH);
        const isDupLock = userStatus === ACCOUNT_STATUSES.DUP_LOCKED;

        if (
            marketIsGB &&
            isPassUser &&
            hasOutOfDateMarketingPrefVersion &&
            !isDupLock &&
            !isOnAllowedRoute(currentPath)
        ) {
            // send them to GDPR flow
            return of(push(PERMISSIONS_PATH));
        } else if (!hasOutOfDateMarketingPrefVersion && isOnGdprRoute) {
            return of(push(ROOT_PATH));
        }

        return [];
    };

    return action$.pipe(
        ofType(profileTypes.PROFILE_FETCH_SUCCESS, profileTypes.PROFILE_FETCH_ERROR),
        flatMap(processGdprRedirect),
    );
};

export default gdprRedirectEpic;
