import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Media from 'react-media';
import cx from 'classnames';
import { mediaQuery } from '@clearscore/rainbow.tokens';
import SvgHamburger from '@clearscore/rainbow.icons.menu-hamburger';
import Logo from '@clearscore/rainbow.icons.clearscore-logo-wordmark';
import Badge from '@clearscore-group/ui.badge';

import styles from './hamburger-navigation.module.css';

function HamburgerNavigation({ hasBurgerMenu, handleToggle, rootHref, logo, noNotifications, logoLinkAriaLabel }) {
    return (
        <div
            className={cx(styles.navigation, {
                [styles.hiddenBurgerMenu]: !hasBurgerMenu,
            })}
            data-qa="side-navigation"
        >
            <Media query={mediaQuery['rnb-mq-large']}>
                {(mediaMatches) =>
                    mediaMatches ? (
                        <NavLink to={rootHref} className={styles.navLink} aria-label={logoLinkAriaLabel}>
                            {React.cloneElement(logo, { className: styles.navigation })}
                        </NavLink>
                    ) : (
                        <button
                            className={styles.hamburger}
                            onClick={handleToggle}
                            data-qa="side-navigation-hamburger"
                            aria-label="Menu"
                            type="button"
                        >
                            {noNotifications ? (
                                <span className={styles.badge}>
                                    <Badge value={noNotifications} />
                                </span>
                            ) : null}
                            <SvgHamburger className={styles.icon} height={36} width={36} />
                        </button>
                    )
                }
            </Media>
        </div>
    );
}

HamburgerNavigation.propTypes = {
    hasBurgerMenu: PropTypes.bool,
    handleToggle: PropTypes.func,
    rootHref: PropTypes.string,
    logo: PropTypes.node,
    noNotifications: PropTypes.number,
    logoLinkAriaLabel: PropTypes.string.isRequired,
};

HamburgerNavigation.defaultProps = {
    hasBurgerMenu: true,
    noNotifications: 0,
    handleToggle: () => {},
    rootHref: '/',
    logo: <Logo height={36} />,
};

export default HamburgerNavigation;
