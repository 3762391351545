import type { ReactElement, ReactNode } from 'react';

import type { useNotificationDrawerContext } from './notification-drawer';

export const WIDTH = {
    FULL_WIDTH: 'full_width',
    SIDE: 'side',
} as const;

export interface INotificationDrawerContext {
    setDrawerWidth?: (width: typeof WIDTH[keyof typeof WIDTH]) => void;
    closeDrawer?: VoidFunction;
    drawerWidth?: typeof WIDTH[keyof typeof WIDTH];
    setExtenderContent?: (content: ReactNode) => void;
}

export interface INotificationDrawer {
    hasNewNotifications: boolean;
}

export type TNotificationDrawer = (props: INotificationDrawer) => ReactElement;

export interface NotificationDrawerExports {
    Width: typeof WIDTH;
    useContext: typeof useNotificationDrawerContext;
}
