import React from 'react';
import CoreApp from '@clearscore-group/ui.external-cs-auth.core';
import GlobalTemplate from '@shared/webapp-template-global';

import AppTemplate from './templates/app';
import RegTemplate from './templates/reg';
import PublicTemplate from './templates/public';
import SimpleTemplate from './templates/simple';
import appBridgeTypes from './libs/action-bridge';
import coreEpics from './libs/epic';
import rootReducers from './libs/reducer';
import middleware from './libs/middleware';

export const storeConfig = {
    reducers: rootReducers,
    name: 'core',
    pageMiddleWare: middleware,
    pageEpics: coreEpics,
    appBridgeTypes,
};

export const templates = {
    app: AppTemplate,
    reg: RegTemplate,
    public: PublicTemplate,
    global: GlobalTemplate,
    simple: SimpleTemplate,
};

const CoreWebapp = () => <CoreApp storeConfig={storeConfig} templates={templates} hasPageTracking />;

export default CoreWebapp;
