import connect from '@clearscore/redux.connect';
import { selectors as marketSelectors } from '@clearscore/redux.market';

import Component from './component';

const mapState = (state) => ({
    currentMarketFetchStatus: marketSelectors.getFetchStatus(state),
    parentState: state,
});

const Container = connect(mapState)(Component);

export default Container;
