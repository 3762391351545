import React from 'react';
import { func, string, array, shape, node, element, oneOfType } from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectors as profileSelectors } from '@clearscore/redux.profile';
import { selectors as sessionSelectors } from '@clearscore/redux.session';

import { ROUTE_NO_MATCH_PROSPECT } from '../../lib/constants';

const isValidProspectRoute = ({ routes, location, currentRole, accountIDVStatus, failureReason }) => {
    const failureRouteExists =
        !!failureReason && routes.find((searchRoute) => searchRoute.failureReason?.includes(failureReason));
    return !!routes.find((route) => {
        const pathRegex = new RegExp(route.path);

        // if path and role fails, definiely wrong place
        if (!pathRegex.test(location.pathname) || !route.role.includes(currentRole)) {
            return false;
        } else if (!route.idvStatus && !route.failureReason) {
            // if path has no guards go there
            return true;
        } else if (failureReason) {
            // failureReason, check if path is specific to failure reason, otherwise
            // if there is no matching failureRoute check idvStatus match
            return (
                route.failureReason?.includes(failureReason) ||
                (!failureRouteExists && route.idvStatus?.includes(accountIDVStatus))
            );
        }
        return route.idvStatus?.includes(accountIDVStatus);
    });
};

const getCorrectProspectRoute = ({ routes, currentRole, accountIDVStatus, failureReason }) => {
    const [failurePath] = routes.filter(
        (route) => route.role.includes(currentRole) && route.failureReason?.includes(failureReason),
    );
    const [validPath] = routes.filter(
        (route) => route.role.includes(currentRole) && route.idvStatus?.includes(accountIDVStatus),
    );
    if (failurePath) return failurePath.path;
    return validPath ? validPath.path : ROUTE_NO_MATCH_PROSPECT;
};

const ProspectRoute = ({ Component, routes, location, path, ...rest }) => {
    const { accountIDVStatus, failureReason } = useSelector(profileSelectors.getStatus);
    const currentRole = useSelector(sessionSelectors.getAuthRole);

    return (
        <Route
            {...rest}
            path={path}
            render={({ match }) =>
                isValidProspectRoute({ routes, location, currentRole, accountIDVStatus, failureReason }) ? (
                    <Component match={match} {...rest} />
                ) : (
                    <Redirect
                        to={{
                            pathname: getCorrectProspectRoute({
                                routes,
                                currentRole,
                                accountIDVStatus,
                                failureReason,
                            }),
                        }}
                    />
                )
            }
        />
    );
};

ProspectRoute.propTypes = {
    routes: array,
    location: shape({
        search: string,
        pathname: string,
    }),
    path: string.isRequired,
    Component: oneOfType([node, element, func]).isRequired,
};

ProspectRoute.defaultProps = {
    routes: [],
    location: {},
};

export default ProspectRoute;
