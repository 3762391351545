/* eslint-disable */
function handleFirstTab(e) {
    if (e.keyCode === 9) {
        document.body.classList.add('isKeyboardUser');
        window.removeEventListener('keydown', handleFirstTab);
        window.addEventListener('mousedown', handleMouseDownOnce);
    }
}

function handleMouseDownOnce() {
    document.body.classList.remove('isKeyboardUser');
    window.removeEventListener('mousedown', handleMouseDownOnce);
    window.addEventListener('keydown', handleFirstTab);
}

export function detectKeyboardUser() {
    window.addEventListener('keydown', handleFirstTab);
}
