const parse = (search) => {
    const pairs = search.slice(1).split('&');
    const result = pairs.reduce((prev, pair) => {
        const [key, encodedValue = ''] = pair.split('=');
        const value = decodeURIComponent(encodedValue);
        const arrValues = prev[key] && Array.isArray(prev[key]) ? prev[key] : [prev[key]];
        const arrValue = prev[key] ? [...arrValues, value] : value;
        return {
            ...prev,
            ...(key ? { [key]: arrValue } : {}),
        };
    }, {});
    return JSON.parse(JSON.stringify(result));
};

const stringify = (params) => {
    const result = Object.keys(params).reduce((prev, key) => {
        const valueArray = Array.isArray(params[key]) ? params[key] : [params[key]];
        return valueArray.reduce((prevValue, decodedValue) => {
            const value = encodeURIComponent(decodedValue);
            const amp = prevValue.length > 0 ? '&' : '';
            return `${prevValue}${amp}${key}=${value}`;
        }, prev);
    }, '');
    return JSON.parse(JSON.stringify(result));
};

export default {
    parse,
    stringify,
};
