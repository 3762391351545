import { logoutEpic } from '@clearscore/epic.session';
import { stageRedirectEpic } from '@clearscore/epic.stage-redirect';
import { marketConfigEpic } from '@clearscore/epic.market-config';
import { trackingEpic as webappTrackingEpic } from '@shared/webapp-tracking';

import gdprRedirectEpic from './epics/gdpr-redirect';
import requestFailedEpic from './epics/request-failed.epic';
import reportNotFoundEpic, { dashboardServiceReportNotFound } from './epics/report-not-found.epic';
import globalNotificationsEpic from './epics/global-notifications.epic';
import refreshNewNotifications from './epics/refresh-new-notifications.epic';
import maintainanceMode from './epics/maintenance-mode-redirect.epic';

export default {
    logoutEpic,
    stageRedirectEpic,
    maintainanceMode,
    gdprRedirectEpic,
    trackingEpic: webappTrackingEpic,
    marketConfigEpic,
    requestFailedEpic,
    reportNotFoundEpic,
    globalNotificationsEpic,
    dashboardServiceReportNotFound,
    ...refreshNewNotifications,
};
