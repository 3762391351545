import type { ReactNode } from 'react';
import React, { useState, createContext, useContext, useMemo } from 'react';
import Drawer from '@clearscore/rainbow.drawer';
import cx from 'classnames';

import Notifications from '../notifications';
import styles from './notification-drawer.module.css';
import type { INotificationDrawerContext, TNotificationDrawer, NotificationDrawerExports } from './types';
import { WIDTH } from './types';

const NotificationDrawerContext = createContext<INotificationDrawerContext>({});
export const useNotificationDrawerContext = (): INotificationDrawerContext => useContext(NotificationDrawerContext);

const NotificationDrawer: TNotificationDrawer & NotificationDrawerExports = ({ hasNewNotifications }) => {
    const { setIsOpen } = Drawer.useContext();
    const [drawerWidth, setDrawerWidth] = useState<typeof WIDTH[keyof typeof WIDTH]>();
    const [extenderContent, setExtenderContent] = useState<ReactNode>(null);

    const closeDrawer = (): void => {
        setDrawerWidth(WIDTH.SIDE);
        setExtenderContent(null);
    };

    const valueMemo = useMemo(
        () => ({ setDrawerWidth, closeDrawer, setExtenderContent }),
        [setDrawerWidth, closeDrawer, setExtenderContent],
    );

    return (
        <NotificationDrawerContext.Provider value={valueMemo}>
            <section
                data-id="notification-area"
                className={cx(styles.notificationArea, {
                    [styles.notificationsFullWidth]: drawerWidth === WIDTH.FULL_WIDTH,
                    [styles.notificationsSideWidth]: drawerWidth === WIDTH.SIDE,
                })}
            >
                <div className={styles.notificationList}>
                    <Notifications
                        handleDrawerClose={(): void => setIsOpen?.(false)}
                        hasNewNotifications={hasNewNotifications}
                    />
                </div>
                <div className={styles.extenderContent} data-id="extender-content">
                    {extenderContent}
                </div>
            </section>
        </NotificationDrawerContext.Provider>
    );
};

NotificationDrawer.Width = WIDTH;
NotificationDrawer.useContext = useNotificationDrawerContext;

export default NotificationDrawer;
