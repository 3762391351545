import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { selectors as profileSelectors } from '@clearscore/redux.profile';
import { selectors as marketSelectors } from '@clearscore/redux.market';
import useLanguage from '@clearscore/hooks.use-language';
import Drawer from '@clearscore/rainbow.drawer';
import SvgAccount from '@clearscore/rainbow.icons.account-hoverable';

import SideMenu from '../side-menu';
import LogoNavigation from '../logo-navigation';
import ProgressNavigation from '../progress-navigation';
import styles from './progress-header.module.css';
import marketConfig from './lib/market-config';
import lang from './lib/lang';

const ProgressHeader = ({ urlMarket }) => {
    const activeMarket = useSelector(marketSelectors.getActiveMarket);
    const { firstName } = useSelector(profileSelectors.getSimple);
    const { progressIndicatorEnabled } = marketConfig({ market: urlMarket || activeMarket });
    const language = useLanguage(lang);

    return (
        <Drawer theme={Drawer.Theme.DARK} placement={Drawer.Placement.RIGHT}>
            <header className={styles.header} role="navigation">
                <LogoNavigation progressIndicatorEnabled={progressIndicatorEnabled} />

                {progressIndicatorEnabled ? (
                    <ProgressNavigation menu="registration" className={styles.headerCenter} />
                ) : (
                    <div className={styles.spacer} />
                )}

                <div
                    data-qa="account-nav-holder"
                    className={progressIndicatorEnabled ? null : styles.headerHiddenSidebar}
                >
                    <Drawer.Toggle asChild>
                        <button
                            type="button"
                            className={cx(styles.myAccountButton)}
                            data-qa="account-open-side-menu"
                            aria-label={language.accountAriaLabel}
                        >
                            {firstName ? (
                                <div
                                    data-qa="account-nav-holder-first-name"
                                    className={cx(styles.accountNavigationName)}
                                >
                                    {language.hi(firstName)}
                                </div>
                            ) : null}
                            <SvgAccount width={32} height={32} />
                        </button>
                    </Drawer.Toggle>
                    <Drawer.Content>
                        <SideMenu market={urlMarket} />
                    </Drawer.Content>
                </div>
            </header>
        </Drawer>
    );
};

ProgressHeader.propTypes = {
    urlMarket: PropTypes.string,
};

ProgressHeader.defaultProps = {
    urlMarket: null,
};

export default ProgressHeader;
