import { flatMap, filter, first } from 'rxjs/operators';
import { of, EMPTY } from 'rxjs';
import { ofType } from 'redux-observable';
import getEnv from '@clearscore/helpers.envs';
import { types as profileTypes } from '@clearscore/redux.profile';
import { PROFILE_STATUSES } from '@clearscore/constants.profile-statuses';

import emailVerification from './email-verification';

const { PASS, NO_FILE, THIN_FILE } = PROFILE_STATUSES;

const selectNotification = (payload) => {
    const isNativeWebview = getEnv('WEBVIEW');
    const selectedAlert = [emailVerification].find(([predicate]) => predicate(payload));

    if (isNativeWebview || !selectedAlert) {
        return null;
    }

    const [, alert] = selectedAlert;
    return alert(payload);
};

export default (action$) =>
    action$.pipe(
        ofType(profileTypes.PROFILE_FETCH_SUCCESS),
        filter(({ payload: { accountIDVStatus } }) => [PASS, NO_FILE, THIN_FILE].includes(accountIDVStatus)),
        first(),
        flatMap(({ payload }) => {
            const notification = selectNotification(payload);

            return notification ? of(notification) : EMPTY;
        }),
    );
