import type { ReactElement } from 'react';
import React from 'react';
import { useHasSegmentationGroups } from '@clearscore/hooks.use-segmentation';
import cx from 'classnames';

import styles from './notification-badge.module.css';

interface INotificationBadge {
    count: number;
    ariaText: string;
}

type TNotificationBadge = (props: INotificationBadge) => ReactElement;

// Badge displays notification count for in app notification experiment
const NotificationBadge: TNotificationBadge = ({ count, ariaText }) => {
    const isInNotificationExperiment = useHasSegmentationGroups('test-web-android-in-app-badge-count:test');

    if (!isInNotificationExperiment) return <span className={styles.badge} />;

    const countString = count > 9 ? `9+` : String(count);
    return (
        <span
            className={cx(styles.badge, styles.badgeExperiment, {
                [styles.badgeWide]: count > 9,
            })}
            aria-label={ariaText}
            data-id="notification-badge"
            role="status"
        >
            <span className={styles.badgeText}>{countString}</span>
        </span>
    );
};

export default NotificationBadge;
