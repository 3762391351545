import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useStore } from 'react-redux';
import cx from 'classnames';
import useLanguage from '@clearscore/hooks.use-language';
import useMarketConfig from '@clearscore/hooks.use-market-config';
import ClearscoreIcon from '@clearscore/rainbow.icons.clearscore-logo-symbol';
import SvgReport from '@clearscore/rainbow.icons.document-hoverable';
import SvgInformation from '@clearscore/rainbow.icons.information-hoverable';
import SvgLogout from '@clearscore/rainbow.icons.log-out-hoverable';
import ProgressRing from '@clearscore/rainbow.progress-ring';
import Text from '@clearscore/rainbow.text';
import { selectors as dashboardSelectors } from '@clearscore/redux.dashboard';
import { selectors as profileSelectors } from '@clearscore/redux.profile';
import { selectors as prospectSelectors } from '@clearscore/redux.prospect';
import { selectors as marketSelectors } from '@clearscore/redux.market';
import { PROFILE_STATUSES, ACCOUNT_STATUSES } from '@clearscore/constants.profile-statuses';
import Badge from '@clearscore-group/ui.badge';
import logout from '@clearscore-group/ui.external-cs-auth.logout';
import { useBetterScoreQuery, useBetterScore } from '@shared/use-better-score';
import DownloadAppModal from '@clearscore-group/ui.download-app-modal';

import lang from './lib/lang';
import marketConfig from './lib/market';
import marketConfigGetApp from './lib/market-config';
import styles from './side-menu.module.css';
import { useTracking } from './lib/hooks/use-tracking';

const DEFAULT_SCORE_THEME = 'DELTA';
const QR_CODE_URL = 'https://clearscore.app.link/JtBfRJ7Llnb';
const PLAY_STORE_URL = 'https://clearscore.app.link/xVgAwTchBob';
const APP_STORE_URL = 'https://clearscore.app.link/u9m0GubhBob';

const SideMenu = ({ market, accountNotifications }) => {
    const language = useLanguage(lang);
    const { trackAccountCtaClicked } = useTracking();
    const { fullName, accountId } = useSelector(profileSelectors.getSimple);
    const oldScore = useSelector(dashboardSelectors.getScores);
    const { accountIDVStatus, userStatus } = useSelector(profileSelectors.getStatus);
    const { email } = useSelector(prospectSelectors.getSimple);
    const activeMarket = useSelector(marketSelectors.getActiveMarket);
    const { termsLink, privacyLink, helpLink } = marketConfig[market || activeMarket];

    const { showGetTheAppCta } = useMarketConfig(marketConfigGetApp);
    const isRegistration =
        ![PROFILE_STATUSES.PASS, PROFILE_STATUSES.NO_FILE].includes(accountIDVStatus) ||
        userStatus === ACCOUNT_STATUSES.DUP_LOCKED;
    const store = useStore();
    const [showDownloadAppModal, setShowDownloadAppModal] = useState(false);
    const { betterScoreEnabled } = useBetterScore();
    const { data = {}, query } = useBetterScoreQuery({ enabled: !isRegistration && betterScoreEnabled });
    const scoreIsLoaded = betterScoreEnabled ? query.isSuccess : true;
    const scoresInUse =
        betterScoreEnabled && scoreIsLoaded ? { ...data.creditScore, theme: DEFAULT_SCORE_THEME } : oldScore;

    const handleLogout = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        logout(store.getState(), store);
    };

    return (
        <div className={styles.content} data-qa="side-menu">
            <div className={styles.contentPrimary}>
                {fullName || email ? (
                    <Text.H2>
                        <div className={styles.name}>{fullName || email}</div>
                    </Text.H2>
                ) : null}

                {accountId && scoreIsLoaded ? (
                    <div className={styles.progressRing}>
                        <ProgressRing
                            value={scoresInUse.score}
                            maxValue={scoresInUse.scoreMax}
                            theme={scoresInUse.theme.toUpperCase()}
                        />
                        <Text type={Text.types.HUGE}>
                            <div className={cx(styles.score, scoresInUse.theme)}>{scoresInUse.score}</div>
                        </Text>
                    </div>
                ) : null}

                <div className={styles.menu}>
                    {accountId ? (
                        <div className={styles.link}>
                            {!isRegistration ? (
                                <Text
                                    type={Text.types.MEDIUM}
                                    href="/my-account"
                                    dataId="side-menu-link-my-account"
                                    isSimpleLink
                                    theme={Text.Link.themes.DARK}
                                >
                                    <div className={styles.iconItem}>
                                        {accountNotifications ? (
                                            <span className={styles.badge}>
                                                <Badge value={accountNotifications} />
                                            </span>
                                        ) : null}
                                        <div className={styles.icon}>
                                            <SvgReport height={32} width={32} />
                                        </div>
                                        <div>{language.myAccount}</div>
                                    </div>
                                </Text>
                            ) : null}
                        </div>
                    ) : null}

                    <div className={styles.link}>
                        <Text
                            type={Text.types.MEDIUM}
                            href={helpLink}
                            dataId="side-menu-link-help"
                            isSimpleLink
                            theme={Text.Link.themes.DARK}
                        >
                            <div className={styles.iconItem}>
                                <div className={styles.icon}>
                                    <SvgInformation height={32} width={32} />
                                </div>
                                <div>{language.help}</div>
                            </div>
                        </Text>
                    </div>
                    {accountId ? (
                        <React.Fragment>
                            <div className={styles.link}>
                                <Text
                                    type={Text.types.MEDIUM}
                                    href="/logout"
                                    dataId="side-menu-link-logout"
                                    isSimpleLink
                                    theme={Text.Link.themes.DARK}
                                >
                                    <span onClick={handleLogout}>
                                        <div className={styles.iconItem}>
                                            <div className={styles.icon}>
                                                <SvgLogout height={32} width={32} />
                                            </div>
                                            <div>{language.logout}</div>
                                        </div>
                                    </span>
                                </Text>
                            </div>

                            {isRegistration ? (
                                <div className={styles.delete}>
                                    <Text
                                        type={Text.types.MINI}
                                        weight={Text.weights.BOLD}
                                        href="/registration/delete-account"
                                        dataId="side-menu-link-delete-account"
                                        theme={Text.Link.themes.DARK}
                                    >
                                        {language.deleteAccount}
                                    </Text>
                                </div>
                            ) : null}
                        </React.Fragment>
                    ) : null}
                    {!isRegistration && showGetTheAppCta ? (
                        <Fragment>
                            <button
                                className={styles.appDownloadLink}
                                onClick={() => {
                                    setShowDownloadAppModal(true);
                                    trackAccountCtaClicked({ copy: language.getTheApp });
                                }}
                                type="button"
                            >
                                <div className={styles.iconItem}>
                                    <div className={styles.iconOuterWrapper}>
                                        <div className={styles.iconInnerWrapper}>
                                            <ClearscoreIcon />
                                        </div>
                                    </div>
                                    <div>{language.getTheApp}</div>
                                </div>
                            </button>
                            <DownloadAppModal
                                isOpen={showDownloadAppModal}
                                onClose={() => setShowDownloadAppModal(false)}
                                appStoreUrl={APP_STORE_URL}
                                playStoreUrl={PLAY_STORE_URL}
                                qrCodeUrl={QR_CODE_URL}
                                playStoreId="side-menu"
                                trackingProperties={{
                                    copy: language.getTheApp,
                                }}
                                modalType={DownloadAppModal.ContentType.NATIVE_INSTALL}
                            />
                        </Fragment>
                    ) : null}
                </div>
            </div>

            <div className={styles.footer}>
                <Text
                    type={Text.types.TINY}
                    weight={Text.weights.BOLD}
                    href={privacyLink}
                    dataId="side-menu-link-privacy"
                    target="_blank"
                >
                    {language.privacyPolicy}
                </Text>

                <Text
                    type={Text.types.TINY}
                    weight={Text.weights.BOLD}
                    href={termsLink}
                    dataId="side-menu-link-terms"
                    target="_blank"
                >
                    {language.terms}
                </Text>
            </div>
        </div>
    );
};

SideMenu.propTypes = {
    market: PropTypes.string,
    accountNotifications: PropTypes.number,
};

SideMenu.defaultProps = {
    market: null,
    accountNotifications: 0,
};

export default SideMenu;
