import useCsBuildNavExperimentImport from './lib/hooks/use-cs-build-nav';

export const BUILD_ROUTE = '/build';
export const OFFERS_ROUTE = '/offers';

export const swapOffersAndBuild = (routes) => {
    const navigation = routes;
    const offersRouteIndex = navigation.findIndex((route) => route.path === OFFERS_ROUTE);
    const buildRouteIndex = navigation.findIndex((route) => route.path === BUILD_ROUTE);
    // Swap position of build and offers
    if (offersRouteIndex > -1 && buildRouteIndex > -1) {
        const offersRoute = navigation[offersRouteIndex];
        navigation[offersRouteIndex] = navigation[buildRouteIndex];
        navigation[buildRouteIndex] = offersRoute;
    }
    return navigation;
};

export const removeBuild = (routes) => routes.filter((route) => route.path !== BUILD_ROUTE);

export const useCsBuildNavExperiment = useCsBuildNavExperimentImport;
