import { GB, ZA, AU, CA } from '@clearscore/config.i18n';

export default {
    [GB]: {
        showGetTheAppCta: true,
    },
    [ZA]: {
        showGetTheAppCta: false,
    },
    [AU]: {
        showGetTheAppCta: true,
    },
    [CA]: {
        showGetTheAppCta: false,
    },
};
