import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import useMarketConfig from '@clearscore/hooks.use-market-config';

import styles from '../icon-navigation.module.css';
import marketConfig from '../lib/market-config';

/**
 * Default icon size when menu is horizontal
 * @type {string}
 */
const horizontalIconSize = '32px';
/**
 * Default icon size when menu is vertical
 * @type {string}
 */
const verticalIconSize = '42px';

/**
 * Default navigation identifier when menu is horizontal
 * @type {string}
 */

export const navIdentifier = 'nav-';

/**
 * Default icon data attribute name when menu is vertical
 * @type {string}
 */
const verticalIconDataTracking = `mob-${navIdentifier}`;

const RouteIcon = ({ direction, icon, dataQa }) => {
    const { newNavEnabled } = useMarketConfig(marketConfig);
    if (!icon) return null;

    const iconStyle = newNavEnabled ? styles.svgIconGb : styles.svgIcon;
    const iconSize = direction === 'horizontal' ? horizontalIconSize : verticalIconSize;
    const dataName = direction === 'horizontal' ? navIdentifier : verticalIconDataTracking;
    const svgDirectionClass = direction === 'horizontal' ? styles.svgHorizontal : styles.svgVertical;
    const { loadAnimation, Svg } = icon;

    return (
        <span className={cx(loadAnimation, iconStyle, svgDirectionClass)} data-qa="route-icon">
            <Svg height={iconSize} width={iconSize} data-tracking={`${dataName}${dataQa}`} />
        </span>
    );
};

RouteIcon.propTypes = {
    direction: PropTypes.oneOf(['horizontal', 'vertical']).isRequired,
    icon: PropTypes.object,
    dataQa: PropTypes.string.isRequired,
};

RouteIcon.defaultProps = {
    icon: PropTypes.null,
};

export default RouteIcon;
