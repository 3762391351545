import type { ReactElement } from 'react';
import React, { Fragment, useContext } from 'react';
import QRCode from 'react-qr-code';
import Text from '@clearscore/rainbow.text';
import Spacer from '@clearscore/rainbow.spacer';
import Stack from '@clearscore/rainbow.stack';
import useLanguage from '@clearscore/hooks.use-language';
import { isDesktop } from '@clearscore/helpers.device';
import cx from 'classnames';
import Modal from '@clearscore/rainbow.modal';

import styles from './competition-content.module.css';
import StoreLinks from '../store-links';
import lang from '../../lib/lang';
import { ARIA_DESCRIBED_BY_ID, ARIA_LABELLED_BY_ID } from '../../lib/constants';
import { ModalContext } from '../../download-app-modal';
import { ModalPlacement } from '../../lib/types';

type CompetitionContent = () => ReactElement;
const CompetitionContent: CompetitionContent = () => {
    const language = useLanguage(lang);
    const isDesktopWeb = isDesktop();
    const { modalPlacement, appStoreUrl, playStoreUrl, qrCodeUrl, playStoreId } = useContext(ModalContext);

    return (
        <section
            className={cx(styles.contentBackground, {
                [styles.placementFullWidth]: modalPlacement === ModalPlacement.FULL_WIDTH,
            })}
            data-id="content-background"
        >
            <div className={styles.content}>
                <section className={styles.contentBody}>
                    <Spacer all={{ top: Spacer.spacings.LARGE, bottom: Spacer.spacings.LARGE }}>
                        <span id={ARIA_LABELLED_BY_ID}>
                            <Text.H2 weight={Text.weights.BOLD}>{language.competitionHeader}</Text.H2>
                            <Text.H3>{language.competitionSubhead}</Text.H3>
                        </span>
                    </Spacer>
                    <Stack all={Stack.spacings.MEDIUM}>
                        {isDesktopWeb && qrCodeUrl ? (
                            <Fragment>
                                <Spacer medium={{ bottom: Spacer.spacings.LARGE }}>
                                    <div className={styles.qrCodeWrapper}>
                                        <div className={styles.scanMe}>
                                            <Text type={Text.types.TINY} weight={Text.weights.BOLD}>
                                                {language.scanMe}
                                            </Text>
                                        </div>
                                        <div className={styles.qrCode} aria-label={language.qrCodeText}>
                                            <QRCode size={78} value={qrCodeUrl} />
                                        </div>
                                    </div>
                                </Spacer>
                                <Text.Body1 id={ARIA_DESCRIBED_BY_ID}>{language.competitionDescription}</Text.Body1>
                            </Fragment>
                        ) : (
                            <Text.Body1 id={ARIA_DESCRIBED_BY_ID}>{language.competitionDescriptionMobile}</Text.Body1>
                        )}
                        <Text.Body2>
                            {language.competitionEligibility}{' '}
                            <a href="www.clearscore.com" target="_blank" rel="noreferrer" className={styles.termsLink}>
                                {language.competitionTerms}
                            </a>
                        </Text.Body2>
                    </Stack>
                </section>

                <Spacer medium={{ top: Spacer.spacings.HUGE }} all={{ top: Spacer.spacings.BIG }}>
                    <StoreLinks appStoreUrl={appStoreUrl} playStoreUrl={playStoreUrl} playStoreId={playStoreId} />
                </Spacer>

                <div className={styles.contentFooter}>
                    <Spacer all={{ bottom: Spacer.spacings.BIG }}>
                        <Modal.Toggle>
                            <button type="button" aria-label={language.ariaClose} className={styles.closeMe}>
                                {language.closeText}
                            </button>
                        </Modal.Toggle>
                    </Spacer>
                </div>
            </div>
        </section>
    );
};

export default CompetitionContent;
