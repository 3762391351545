import { types as identityTypes } from '@clearscore/redux.identity';
import { types as marketTypes } from '@clearscore/redux.market';
import { types as productsTypes } from '@clearscore/redux.products';
import { types as profileTypes } from '@clearscore/redux.profile';
import { types as sessionTypes } from '@clearscore/redux.session';
import { types as alertsTypes } from '@clearscore/redux.alerts';

export default [
    identityTypes.IDENTITY_FIRST_TIME_REGISTRATION,
    marketTypes.MARKET_SET_ACTIVE_MARKET,
    marketTypes.MARKET_FETCH_CONFIG_SUCCESS,
    marketTypes.MARKET_FETCH_CONFIG_ERROR,
    marketTypes.MARKET_SET_ACTIVE_LOCALE,
    productsTypes.STORE_RESULTS_REQUEST_DATA,
    profileTypes.PROFILE_FETCH_SUCCESS,
    sessionTypes.LOGOUT_SUBMIT,
    sessionTypes.SET_SESSION,
    sessionTypes.TIMEOUT_SUCCESS,
    sessionTypes.LOGIN_OAUTH_AUTHORISE_SUCCESS,
    alertsTypes.ALERTS_SHOW_ALERT,
    alertsTypes.ALERTS_HIDE_ALERT,
];
