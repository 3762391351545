import type { ReactElement, ReactNode } from 'react';
import React, { useContext, useState, useCallback, useMemo } from 'react';
import { createGlobalContext } from '@clearscore/helpers.create-global-context';

import { VERTICAL_TAKEOVER_CONTEXT_NAME } from './lib/constants';
import VerticalTakeoverLazyRenderer from './components/vertical-takeover-lazy-renderer';
import type { VerticalTakeoverContextValue, VerticalTakeoverTriggerProps } from './vertical-takeover.types';

const VerticalTakeoverContext = createGlobalContext<VerticalTakeoverContextValue>(
    VERTICAL_TAKEOVER_CONTEXT_NAME,
    undefined,
);

interface VerticalTakeoverProviderProps {
    children: ReactNode;
}

const VerticalTakeoverProvider = ({ children }: VerticalTakeoverProviderProps): ReactElement => {
    const [verticalName, setVerticalName] = useState<string | undefined>();
    const [triggerProps, setTriggerProps] = useState<VerticalTakeoverTriggerProps | undefined>();

    const trigger = useCallback((name: string, input: VerticalTakeoverTriggerProps): void => {
        setVerticalName(name);
        setTriggerProps(input);
    }, []);

    const close = useCallback(() => {
        setVerticalName(undefined);
        setTriggerProps(undefined);
    }, []);

    const value: VerticalTakeoverContextValue = useMemo(
        () => ({
            close,
            trigger,
        }),
        [close, trigger],
    );

    return (
        <VerticalTakeoverContext.Provider value={value}>
            {children}
            {verticalName && triggerProps && (
                <VerticalTakeoverLazyRenderer name={verticalName} onClose={close} triggerProps={triggerProps} />
            )}
        </VerticalTakeoverContext.Provider>
    );
};

const useVerticalTakeoverContext = (): VerticalTakeoverContextValue | undefined => {
    const context = useContext(VerticalTakeoverContext);
    return context;
};

export { VerticalTakeoverProvider, useVerticalTakeoverContext };
