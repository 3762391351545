import type { ReactElement } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { MARKETS, GLOBAL } from '@clearscore/config.i18n';
import { selectors as dashboardSelectors } from '@clearscore/redux.dashboard';
import { selectors as marketSelectors } from '@clearscore/redux.market';
import * as featureToggle from '@clearscore-group/ui.dev-tools.feature-toggle';

import styles from './app-background.module.css';
import marketConfig from './lib/market-config';

const seasonalBackgroundFeature = featureToggle.get('seasonalBackgrounds') || {};
const env = seasonalBackgroundFeature.environment || 'production';
const HOST = `//media.clearscore.com`;
const IMAGE_NORMAL = 'normal';
const IMAGE_BLURRED = 'blurred';
const FORMATS = ['avif', 'webp', 'jpg'] as const; // order matters, we want to try to serve avif first, then webp, then finally jpg
const sizes: { orientation: Orientation; width: Width }[] = [
    { orientation: 'portrait', width: 720 },
    { orientation: 'portrait', width: 1440 },
    { orientation: 'landscape', width: 720 },
    { orientation: 'landscape', width: 1280 },
    { orientation: 'landscape', width: 1920 },
];

type Format = typeof FORMATS[number];
type Focus = boolean | undefined;
type Orientation = 'landscape' | 'portrait';
type Width = 720 | 1440 | 720 | 1280 | 1920;

interface GetImgUrl {
    market: any;
    theme: string;
    orientation: Orientation;
    width: Width;
    format: Format;
    focus?: Focus;
    isNighttime?: boolean;
    cacheString: string;
}

const getImgUrl = ({
    market,
    theme: originalTheme,
    orientation,
    width,
    focus,
    format,
    isNighttime,
    cacheString,
}: GetImgUrl): string => {
    // TODO: fix this and all hacks in here with JIRA CS-71732
    const { seasonOverride } = marketConfig[market];
    const season = seasonOverride({ month: new Date(Date.now()).getMonth() + 1 });
    const blur = focus ? '' : '-blur';
    const time = isNighttime ? '' : ''; // soon: '-night'
    const theme = originalTheme === 'no-file' ? 'theta' : originalTheme;
    const chosenMarket = seasonalBackgroundFeature.market || market;
    // use cache-busting string when testing images with 'featureToggle' (i.e. from within cs-dev-tools) turned on
    // additional cache-busting added since the '1st sept 2021' date was missed for new images
    const cacheBust = seasonalBackgroundFeature.market ? `?${cacheString}` : '?winter2022-v1';
    // eslint-disable-next-line max-len
    return `${HOST}/${chosenMarket}/images/seasonal/${season}/${env}/${theme}/web/${orientation}-${width}/background${blur}${time}.${format}${cacheBust}`;
};

const useTimes = (): { isNighttime: boolean } =>
    // useMemo in case the user is viewing the page when it crosses over into nighttime / daytime (also performance)
    React.useMemo(() => {
        const now = new Date(Date.now());
        const hourOfDay = now.getHours();
        const isNighttime = hourOfDay > 18 || hourOfDay < 7; // Later than 7pm or earlier than 7am
        return { isNighttime };
    }, []);

interface AppBackground {
    overrideMarket?: any;
    theme?: string;
    focus?: Focus;
    transparent?: boolean;
    hasGradient?: boolean;
}

const AppBackground = ({
    overrideMarket,
    theme: overrideTheme,
    focus,
    transparent,
    hasGradient,
}: AppBackground): ReactElement | null => {
    const cacheString = Math.random().toString(36);
    const { isNighttime } = useTimes();
    const storeMarket = useSelector(marketSelectors.getActiveMarket);
    const market = overrideMarket || storeMarket;
    const isActualMarket = [...MARKETS, GLOBAL].includes(market);
    const scores = useSelector(dashboardSelectors.getScores) as { theme: string };
    const theme = marketConfig[market]?.getTheme({ overrideTheme, storeTheme: scores.theme });
    const type = focus ? IMAGE_NORMAL : IMAGE_BLURRED;
    const time = isNighttime ? 'night' : 'day';
    const defaultUrlArgs = { market, theme, focus, isNighttime };
    if (transparent || !isActualMarket) return null;

    return (
        <span
            className={cx(styles.appBackground, {
                [styles.default]: theme === 'default',
                [styles.gradient]: hasGradient,
            })}
            data-qa="app-background"
            data-id={`${market}-${type}-${theme}-${time}`}
        >
            <picture>
                {FORMATS.map((format) => (
                    <source
                        key={format}
                        src={getImgUrl({
                            ...defaultUrlArgs,
                            orientation: 'landscape',
                            width: 720,
                            format,
                            cacheString,
                        })}
                        sizes="100vw"
                        type={`image/${format}`}
                        srcSet={sizes
                            .map(
                                ({ orientation, width }) =>
                                    `${getImgUrl({
                                        ...defaultUrlArgs,
                                        orientation,
                                        width,
                                        format,
                                        cacheString,
                                    })} ${width}w`,
                            )
                            .join(',\n')}
                    />
                ))}
                <img
                    alt=""
                    src={getImgUrl({
                        market,
                        theme,
                        orientation: 'landscape',
                        width: 720,
                        format: 'jpg',
                        cacheString,
                    })}
                />
            </picture>
        </span>
    );
};

export default AppBackground;
