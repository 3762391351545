import { useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';

const useScrollToTop = () => {
    const history = useHistory();

    useLayoutEffect(
        () =>
            history.listen(() => {
                window.scrollTo(0, 0);
            }),
        [], // eslint-disable-line react-hooks/exhaustive-deps
    );
};

export default useScrollToTop;
