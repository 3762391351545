import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import getEnv from '@clearscore/helpers.envs';
import ConnectedAlert from '@clearscore-group/ui.alert';
import LazyLoadComponent from '@shared/lazy-load';

import Header from '../../components/header';
import styles from './simple-template.module.css';

const SimpleTemplate = ({ route, parentState, dispatch, match, coreAppBridgeTypes, currentMarketFetchStatus }) => {
    const isNativeWebview = getEnv('WEBVIEW');
    return (
        <Fragment>
            {!isNativeWebview ? (
                <Header
                    hasIconNavigation={false}
                    hasBurgerMenu={false}
                    routerTrackingSource="global-navigation"
                    isSimpleHeader
                />
            ) : null}

            <div className={styles.contentWrapper}>
                <LazyLoadComponent
                    component={{
                        ...route,
                        computedMatch: match,
                        parentProps: {
                            initialState: parentState,
                            dispatch,
                            coreAppBridgeTypes,
                            currentMarketFetchStatus,
                        },
                    }}
                />
            </div>

            <ConnectedAlert dataQa="app-template-alert" isBelowHeader />
        </Fragment>
    );
};

SimpleTemplate.propTypes = {
    route: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    parentState: PropTypes.object.isRequired,
    currentMarketFetchStatus: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    coreAppBridgeTypes: PropTypes.array.isRequired,
};

export default SimpleTemplate;
