import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useWebappTracking from '@clearscore/hooks.use-webapp-tracking';
import { selectors as notificationsSelectors } from '@clearscore/redux.notifications';

export default () => {
    const track = useWebappTracking();
    const dashboardFetchHasNewNotificationsStatus = useSelector(
        notificationsSelectors.fetchHasNewNotifications.getStatus,
    );
    const dashboardFetchStatus = useSelector(notificationsSelectors.fetch.getStatus);
    const { hasNew: newNotifications } = useSelector(notificationsSelectors.getHasNewNotifications);
    const badgeShown = newNotifications.toString();

    const trackNotificationIconViewed = (props) =>
        track({
            name: 'fe_in_app_notification_icon_viewed',
            props: {
                badge_shown: badgeShown,
                ...props,
            },
        });

    useEffect(() => {
        if (
            dashboardFetchHasNewNotificationsStatus.isComplete &&
            (!dashboardFetchStatus.isComplete || !dashboardFetchStatus.hasFailed)
        ) {
            trackNotificationIconViewed({
                response_status: 'success',
            });
        }
    }, [
        dashboardFetchHasNewNotificationsStatus.isComplete,
        dashboardFetchStatus.isComplete,
        dashboardFetchStatus.hasFailed,
    ]);

    useEffect(() => {
        if (
            dashboardFetchHasNewNotificationsStatus.hasFailed &&
            (!dashboardFetchStatus.isComplete || !dashboardFetchStatus.hasFailed)
        ) {
            trackNotificationIconViewed({
                response_status: 'failure',
            });
        }
    }, [
        dashboardFetchHasNewNotificationsStatus.hasFailed,
        dashboardFetchStatus.isComplete,
        dashboardFetchStatus.hasFailed,
    ]);

    return {
        trackNotificationsClick: ({ id, title, description, destinationUrl, index, isSeen }) =>
            track({
                name: 'fe_in_app_notification_clicked',
                props: {
                    notification_id: id,
                    title,
                    description,
                    destination_url: destinationUrl,
                    notification_rank_in_page: index,
                    view_type: isSeen,
                },
            }),
    };
};
