import React from 'react';
import { string, func, node, element, oneOfType } from 'prop-types';
import { Route } from 'react-router-dom';

const UnauthRoute = ({ Component, path, ...rest }) => (
    <Route path={path} {...rest} render={({ match }) => <Component match={match} {...rest} />} />
);

UnauthRoute.propTypes = {
    path: string.isRequired,
    Component: oneOfType([node, element, func]).isRequired,
};

export default UnauthRoute;
