import useBranchLoginTracking from './use-branch-login-tracking';

/**
 * This hook calls all the other core template hooks that watch for / update the user's authentication state
 *
 * Any hooks that, for example, log when a user goes from prospect -> authorised, should live here
 */
const useAuthListeners = () => {
    useBranchLoginTracking();
};

export default useAuthListeners;
