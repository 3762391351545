import React from 'react';
import PropTypes from 'prop-types';
import connect from '@clearscore/redux.connect';
import getEnv from '@clearscore/helpers.envs';
import Logo from '@clearscore/rainbow.icons.clearscore-logo-wordmark';
import LazyLoadComponent from '@shared/lazy-load';
import ConnectedAlert from '@clearscore-group/ui.alert';

import styles from './index.module.css';
import FullWidthContainer from './full-width-container';

const websiteUrl = getEnv('INTERNATIONAL_WEBSITE');

const GlobalTemplate = ({ route, parentState, dispatch, match, coreAppBridgeTypes }) => (
    <div className={styles.contentWrapper}>
        <div className={styles.alertContainer}>
            <ConnectedAlert />
        </div>

        <div className={styles.headerWrapper}>
            <FullWidthContainer>
                <header className={styles.header} role="navigation">
                    <a href={websiteUrl}>
                        <Logo className={styles.logoIcon} />
                    </a>
                </header>
            </FullWidthContainer>
        </div>

        <LazyLoadComponent
            component={{
                ...route,
                computedMatch: match,
                parentProps: {
                    initialState: parentState,
                    dispatch,
                    coreAppBridgeTypes,
                },
            }}
        />
    </div>
);

GlobalTemplate.propTypes = {
    route: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    parentState: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    coreAppBridgeTypes: PropTypes.array.isRequired,
};

const mapState = (state) => ({
    parentState: state,
});

export default connect(mapState)(GlobalTemplate);
