import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { selectors as routeSelectors } from '@clearscore/redux.routes';
import ProgressIndicatorWithNumber from '@clearscore/shared.progress-indicator-with-number';

import styles from './index.module.css';
import getSteps from './lib/get-steps';

const ProgressNavigation = ({ className, ...props }) => {
    const routes = useSelector((state) => routeSelectors.routeSelector(state, props));
    const {
        route: { displayName },
    } = useSelector(routeSelectors.getRouteByIDVStatus);

    const step = parseInt(displayName || '0', 10);
    const steps = getSteps(routes);
    const navigationClass = cx(className, styles.navigation);
    const numbers = steps.map((thisStep) => {
        const active = thisStep <= step;
        const completed = thisStep < step;
        return (
            <ProgressIndicatorWithNumber
                key={thisStep.toString()}
                step={thisStep}
                isActive={active}
                isComplete={completed}
            />
        );
    });

    return <div className={navigationClass}>{steps && numbers}</div>;
};

ProgressNavigation.propTypes = {
    className: PropTypes.string,
};

ProgressNavigation.defaultProps = {
    className: null,
};

export default ProgressNavigation;
