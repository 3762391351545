import useBranchTracking from '@clearscore/hooks.use-branch-tracking';

export default () => {
    const track = useBranchTracking();

    return {
        trackLogin() {
            track('LOGIN');
        },
    };
};
