import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useBetterScoreQuery, useBetterScore } from '@shared/use-better-score';

import DashboardSvg from './dashboard.svg';
import styles from './styles.module.css';

function DashboardIcon(props) {
    const { theme, size, score, dispatch, ...rest } = props;

    const { betterScoreEnabled } = useBetterScore();
    const { data = {}, query } = useBetterScoreQuery({ enabled: betterScoreEnabled });

    const scoresInUse = betterScoreEnabled ? data.creditScore : { score };
    const scoreIsLoaded = betterScoreEnabled ? query.isSuccess : true;

    const svgClass = cx(styles.dashboardIcon, {
        [styles.dashboardAlpha]: theme === 'alpha' || theme === 'default',
        [styles.dashboardBeta]: theme === 'beta',
        [styles.dashboardDelta]: theme === 'delta',
        [styles.dashboardEpsilon]: theme === 'epsilon',
        [styles.dashboardGamma]: theme === 'gamma',
    });

    return (
        <span className={svgClass} style={{ height: size, width: size }} data-name="dashboard">
            <DashboardSvg height={size} width={size} {...rest} />
            <span className="icon-score">{scoreIsLoaded ? scoresInUse.score : '?'}</span>
        </span>
    );
}

DashboardIcon.propTypes = {
    dispatch: PropTypes.func,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    score: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    theme: PropTypes.string.isRequired,
    children: PropTypes.any,
};

DashboardIcon.defaultProps = {
    children: DashboardSvg,
    size: 32,
    dispatch: () => {},
};

export default DashboardIcon;
