import connect from '@clearscore/redux.connect';

import Component from './component';

const mapState = (state) => ({
    parentState: state,
});

const Container = connect(mapState)(Component);

export default Container;
