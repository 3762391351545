import { AU, GB, ZA } from '@clearscore/config.i18n';

export default ({ enabled }) => ({
    [GB]: {
        betterScoreEnabled: true,
    },
    [ZA]: {
        betterScoreEnabled: enabled,
    },
    [AU]: {
        betterScoreEnabled: enabled,
    },
});
