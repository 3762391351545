import useWebappTracking from '@clearscore/hooks.use-webapp-tracking';

export const TRACK_MODAL_OPENED = 'fe_modal_opened';
export const TRACK_MODAL_CTA_CLICKED = 'fe_modal_cta_clicked';

interface TUseTracking {
    trackModalOpened: ({ copy }: { copy?: string }) => void;
    trackModalCtaClicked: ({ type }: { type: string }) => void;
}

export const useTracking = (): TUseTracking => {
    const track = useWebappTracking();

    return {
        trackModalOpened: ({ copy }): void =>
            track({
                name: TRACK_MODAL_OPENED,
                props: {
                    type: 'native_app_install',
                    copy,
                },
            }),
        trackModalCtaClicked: ({ type }): void =>
            track({
                name: TRACK_MODAL_CTA_CLICKED,
                props: {
                    type,
                },
            }),
    };
};
