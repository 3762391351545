import React, { useMemo } from 'react';
import Modal from '@clearscore/rainbow.modal';
import useLanguage from '@clearscore/hooks.use-language';

import { useTracking } from './lib/hooks/use-tracking';
import { useModalContent } from './lib/hooks/use-modal-content';
import lang from './lib/lang';
import type { DownloadAppModalExports, TDownloadAppModal, IModalContext } from './lib/types';
import { ModalType, ModalPlacement } from './lib/types';
import { ARIA_DESCRIBED_BY_ID, ARIA_LABELLED_BY_ID } from './lib/constants';

// Using assertion as we don't use the context outside of the provider & don't need defaults
export const ModalContext = React.createContext<IModalContext>({} as IModalContext);

const DownloadAppModal: TDownloadAppModal & DownloadAppModalExports = ({
    isOpen = false,
    onOpen,
    onClose,
    appStoreUrl,
    playStoreUrl,
    qrCodeUrl,
    playStoreId,
    trackingProperties,
    modalType,
    modalPlacement = ModalPlacement.CENTRE,
}) => {
    const { content } = useModalContent({ modalType });
    const { trackModalOpened } = useTracking();
    const language = useLanguage(lang);

    const value = useMemo(
        () => ({ modalType, modalPlacement, appStoreUrl, playStoreUrl, qrCodeUrl, playStoreId }),
        [modalType, modalPlacement, appStoreUrl, playStoreUrl, qrCodeUrl, playStoreId],
    );

    // determines if using css transition or animation 'fade' on load
    const animated = modalPlacement === ModalPlacement.FULL_WIDTH;

    return (
        <ModalContext.Provider value={value}>
            <Modal
                isOpenOverride={isOpen}
                onClose={(): void => onClose?.()}
                onOpen={(): void => {
                    trackModalOpened(trackingProperties);
                    onOpen?.();
                }}
            >
                <Modal.Backdrop animate={animated} />
                <Modal.Content
                    animate={animated}
                    ariaLabel={language.ariaModalContent}
                    ariaCloseLabel={language.ariaClose}
                    ariaLabelledBy={ARIA_LABELLED_BY_ID}
                    ariaDescribedBy={ARIA_DESCRIBED_BY_ID}
                >
                    {content}
                </Modal.Content>
            </Modal>
        </ModalContext.Provider>
    );
};

DownloadAppModal.ContentType = ModalType;
DownloadAppModal.Placement = ModalPlacement;

export default DownloadAppModal;
