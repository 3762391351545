import { GB, ZA, AU, CA } from '@clearscore/config.i18n';

export default {
    [ZA]: {
        reportNotFoundHelpLink:
            'https://help.clearscore.co.za/hc/en-us/articles/360012074060-I-m-having-trouble-accessing-my-report-',
        termsUrl: 'https://www.clearscore.com/za/terms',
    },

    [AU]: {
        reportNotFoundHelpLink: 'https://help.clearscore.com.au/hc/en-us/articles/360012074620',
        termsUrl: 'https://www.clearscore.com/au/terms',
    },

    [CA]: {
        reportNotFoundHelpLink: 'https://help.clearscore.ca/hc/en-us',
        termsUrl: 'https://www.clearscore.com/ca/terms',
    },

    [GB]: {
        reportNotFoundHelpLink:
            'https://help.clearscore.com/hc/en-us/articles/360012073620-I-m-having-trouble-accessing-my-report-',
        termsUrl: 'https://www.clearscore.com/terms',
    },
};
