import React from 'react';
import PropTypes from 'prop-types';
import Text from '@clearscore/rainbow.text';

import styles from './styles.module.css';

function Badge({ value, dataId }) {
    return (
        <span className={styles.badge} data-id={dataId} data-qa="badge">
            <Text type={Text.types.MICRO} weight={Text.weights.BOLD} dataId="badge-value">
                {value}
            </Text>
        </span>
    );
}

Badge.propTypes = {
    value: PropTypes.number.isRequired,
    dataId: PropTypes.string,
};

Badge.defaultProps = {
    dataId: undefined,
};

export default Badge;
