import React, { lazy, useEffect } from 'react';
import PropTypes from 'prop-types';
import { css } from '@clearscore/helpers.injectors';
import Loader from '@clearscore/rainbow.loader';
import { getCookie } from '@clearscore/helpers.cookies';

// needed for chrome 62, edge 17, edge 18
import { importModule } from './polyfill/import-module';
import LegacyBrowserLazyComponent from './legacy-browser-lazy-component';

const currentStore = {};

// Placeholder, needed for tests
let getLazy = () => () => null;
if (process.env.MODULE === 'esm') {
    getLazy = (path) => lazy(() => importModule(path).then((module) => ({ default: module.Component })));
}

const useLazy = (assetPath) => {
    const lazyComponentStore = React.useRef({}).current;
    if (!lazyComponentStore[assetPath]) {
        lazyComponentStore[assetPath] = getLazy(assetPath.replace('index.js', 'index.mjs').replace('/resources/', '/'));
    }
    return lazyComponentStore[assetPath];
};

const EsmComponent = ({ component }) => {
    const { vertical, component: componentName, parentProps, routeProps, computedMatch } = component;
    const assetPath = vertical ?? componentName;
    const Lazy = useLazy(assetPath);

    // fetching config causes a rerender of the whole app
    // this checks allows us to wait it out
    // so will show the loading spinner once instead of twice
    if (parentProps.currentMarketFetchStatus?.isPending) {
        return <Loader isFullPage theme={Loader.Theme.LIGHT} />;
    }

    return (
        <React.Suspense fallback={() => <Loader isFullPage theme={Loader.Theme.LIGHT} />}>
            <Lazy
                {...parentProps}
                {...routeProps}
                match={computedMatch}
                currentStore={currentStore}
                routeConfig={component}
            />
        </React.Suspense>
    );
};

EsmComponent.propTypes = {
    component: PropTypes.shape({
        routeProps: PropTypes.object.isRequired,
        computedMatch: PropTypes.object.isRequired,
        parentProps: PropTypes.object.isRequired,
        name: PropTypes.string.isRequired,
        vertical: PropTypes.string,
        component: PropTypes.string,
        esmodules: PropTypes.bool,
    }).isRequired,
};

const LazyLoadComponent = ({ component }) => {
    const { vertical, component: componentName, esmodules } = component;
    const assetPath = vertical ?? componentName;
    const hasForcedCjs = getCookie('experiment-esm') === 'development'; // allow us to force cjs verticals for cypress
    const isModernBrowser = process.env.MODULE === 'esm'; // is this porta mjs core build
    const isEsmVertical = isModernBrowser && esmodules && !hasForcedCjs;

    useEffect(() => {
        // we need to load the styles which are named like `index.css` because of naming clashes
        // for both modules and nomodules
        // while we are rending esm and cjs together we need to check that the asset path is esm
        if (isEsmVertical) {
            css(`${assetPath.slice(0, assetPath.indexOf('index'))}index.css`.replace('/resources/', '/'));
        }
    }, [assetPath]);

    // while we are rending esm and cjs together we need to check that the asset path is esm
    if (isEsmVertical) {
        return <EsmComponent component={component} />;
    }

    return <LegacyBrowserLazyComponent component={component} />;
};

LazyLoadComponent.propTypes = {
    component: PropTypes.shape({
        routeProps: PropTypes.object.isRequired,
        computedMatch: PropTypes.object.isRequired,
        parentProps: PropTypes.object.isRequired,
        name: PropTypes.string.isRequired,
        vertical: PropTypes.string,
        component: PropTypes.string,
        esmodules: PropTypes.bool,
    }).isRequired,
};

export default LazyLoadComponent;
