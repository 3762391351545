import React from 'react';
import { shape, string, array } from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectors as sessionSelectors } from '@clearscore/redux.session';
import { selectors as profileSelectors } from '@clearscore/redux.profile';
import getEnv from '@clearscore/helpers.envs';
import querystring from '@shared/query-string';

import {
    ROUTE_NO_MATCH_UNAUTH,
    ROUTE_NO_MATCH_AUTH,
    ROUTE_NO_MATCH_PROSPECT,
    AUTHORISED,
    PROSPECT,
} from '../../lib/constants';

const getUnauthedRedirect = (location) => {
    const [NO_MATCH_UNAUTH = ROUTE_NO_MATCH_UNAUTH] = getEnv(['ROUTE_NO_MATCH_UNAUTH']);
    if (location.pathname !== '/') {
        // eslint-disable-next-line camelcase
        const { redirect, client_id, client_type, device_id, device_name, session_id, ...rest } = querystring.parse(
            location.search,
        );
        const search = querystring.stringify(rest);
        return {
            pathname: NO_MATCH_UNAUTH,
            search: `redirect=${location.pathname}${search ? `&${search}` : ''}`,
        };
    }
    return { pathname: NO_MATCH_UNAUTH };
};

const getAuthedRedirect = (location) => {
    const [NO_MATCH_AUTH = ROUTE_NO_MATCH_AUTH] = getEnv(['ROUTE_NO_MATCH_AUTH']);
    const { redirect, ...rest } = querystring.parse(location.search);
    const search = querystring.stringify(rest);
    return {
        pathname: redirect || NO_MATCH_AUTH,
        search: search || '',
        state: location.state,
    };
};

const getProspectRedirect = ({ routes, currentRole, accountIDVStatus, failureReason }) => {
    const [NO_MATCH_PROSPECT = ROUTE_NO_MATCH_PROSPECT] = getEnv(['ROUTE_NO_MATCH_PROSPECT']);
    const [failurePath] = routes.filter(
        (route) => route.role.includes(currentRole) && route.failureReason?.includes(failureReason),
    );
    const [validPath] = routes.filter(
        (route) => route.role.includes(currentRole) && route.idvStatus?.includes(accountIDVStatus),
    );
    if (failurePath) return { pathname: failurePath.path, search: '' };
    return { pathname: validPath ? validPath.path : NO_MATCH_PROSPECT, search: '' };
};

const getFallThroughRedirect = ({ currentRole, location, routes, accountIDVStatus, failureReason }) => {
    switch (currentRole) {
        case AUTHORISED:
            return getAuthedRedirect(location);
        case PROSPECT:
            return getProspectRedirect({ currentRole, routes, accountIDVStatus, failureReason });
        default:
            return getUnauthedRedirect(location);
    }
};

const NoMatchRoute = ({ location, routes }) => {
    const currentRole = useSelector(sessionSelectors.getAuthRole);
    const { accountIDVStatus, failureReason } = useSelector(profileSelectors.getStatus);
    const redirectTo = getFallThroughRedirect({ currentRole, location, routes, accountIDVStatus, failureReason });
    return <Redirect to={{ ...redirectTo }} />;
};

NoMatchRoute.propTypes = {
    routes: array,
    location: shape({
        search: string,
        pathname: string,
    }),
};

NoMatchRoute.defaultProps = {
    location: {},
    routes: [],
};

export default NoMatchRoute;
