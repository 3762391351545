import type { ReactElement } from 'react';
import React, { Fragment } from 'react';
import useLanguage from '@clearscore/hooks.use-language';

import PlayStoreIcon from './play-store-icon';
import AppStoreIcon from '../../../assets/app-store-icon.svg';
import styles from './store-links.module.css';
import lang from '../../lib/lang';
import { useTracking } from '../../lib/hooks/use-tracking';

const DEVICE = {
    IOS: 'ios',
    ANDROID: 'android',
};

interface StoreLinksProps {
    appStoreUrl: string;
    playStoreUrl: string;
    playStoreId: string;
}

type StoreLinks = (props: StoreLinksProps) => ReactElement;

const StoreLinks: StoreLinks = ({ appStoreUrl, playStoreUrl, playStoreId }) => {
    const language = useLanguage(lang);
    const { trackModalCtaClicked } = useTracking();

    return (
        <Fragment>
            <a
                className={styles.link}
                target="_blank"
                title={language.appStoreTitle}
                rel="noopener noreferrer"
                href={appStoreUrl}
                data-qa="store-cta"
                onClick={(): void => trackModalCtaClicked({ type: DEVICE.IOS })}
            >
                <AppStoreIcon className={styles.storeButton} />
            </a>
            <a
                className={styles.link}
                target="_blank"
                title={language.playStoreTitle}
                rel="noopener noreferrer"
                href={playStoreUrl}
                data-qa="store-cta"
                onClick={(): void => trackModalCtaClicked({ type: DEVICE.ANDROID })}
            >
                <PlayStoreIcon id={playStoreId} />
            </a>
        </Fragment>
    );
};

export default StoreLinks;
