import { flatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { push } from 'connected-react-router';

const maintenanceModeRedirect = (action$) =>
    action$.pipe(
        ofType('CS/MAINTENANCE_MODE_ENABLED'),
        flatMap(() => of(push('/maintenance'))),
    );

export default maintenanceModeRedirect;
