import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import branch from '@clearscore/helpers.branch';
import { selectors as profileSelectors } from '@clearscore/redux.profile';
import useTracking from '@clearscore/hooks.use-tracking';

/**
 * Run an analytics and branch `identify` call when the logged in user changes, so subsequent tracking
 * events are sent with the correct `userId` and `developer_identity` properties
 */
const useIdentifyOnProfileFetch = () => {
    const track = useTracking();

    const { uuid } = useSelector(profileSelectors.getSimple) ?? {};

    useEffect(() => {
        if (uuid) {
            // Segment / analytics identification
            track({
                name: uuid,
                event: 'identify',
            });

            // Branch identification
            branch.setIdentity(uuid);
        }
    }, [uuid]);
};

export default useIdentifyOnProfileFetch;
