import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import * as roles from '@clearscore/constants.role-statuses';
import getEnv from '@clearscore/helpers.envs';
import ClearScoreLogoNoText from '@clearscore/rainbow.icons.clearscore-logo-symbol';
import ClearScoreLogoWithText from '@clearscore/rainbow.icons.clearscore-logo-wordmark';
import { selectors as sessionSelectors } from '@clearscore/redux.session';

import styles from './logo-navigation.module.css';

const Icons = () => (
    <React.Fragment>
        <ClearScoreLogoNoText className={styles.logoNoText} />
        <ClearScoreLogoWithText className={styles.logoWithText} />
    </React.Fragment>
);

const Logo = () => {
    const role = useSelector(sessionSelectors.getAuthRole);
    if (role === roles.AUTHORISED) {
        return (
            <NavLink to="/" aria-label="ClearScore Dashboard">
                <Icons />
            </NavLink>
        );
    } else if (getEnv('WEBVIEW')) {
        return <Icons />;
    }
    return (
        <a href={getEnv('INTERNATIONAL_WEBSITE')} aria-label="ClearScore">
            <Icons />
        </a>
    );
};

const LogoNavigation = ({ progressIndicatorEnabled }) => (
    <div className={cx(styles.logoNavigation, { [styles.headerHidden]: !progressIndicatorEnabled })}>
        <Logo />
    </div>
);

LogoNavigation.propTypes = {
    progressIndicatorEnabled: PropTypes.bool,
};

LogoNavigation.defaultProps = {
    progressIndicatorEnabled: true,
};

export default LogoNavigation;
